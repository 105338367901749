import { ReactNode } from 'react';
import * as RadixScrollArea from '@radix-ui/react-scroll-area';
import styles from './scroll-area.module.css';

interface ScrollAreaProps {
	children: ReactNode;
}

const ScrollArea = ({ children }: ScrollAreaProps) => (
	<RadixScrollArea.Root type="auto" className={styles.ScrollAreaRoot}>
		<RadixScrollArea.Viewport className={styles.ScrollAreaViewport}>
			{children}
		</RadixScrollArea.Viewport>
		<RadixScrollArea.Scrollbar
			className={styles.ScrollAreaScrollbar}
			orientation="vertical"
		>
			<RadixScrollArea.Thumb className={styles.ScrollAreaThumb} />
		</RadixScrollArea.Scrollbar>
		<RadixScrollArea.Scrollbar
			className={styles.ScrollAreaScrollbar}
			orientation="horizontal"
		>
			<RadixScrollArea.Thumb className={styles.ScrollAreaThumb} />
		</RadixScrollArea.Scrollbar>
		<RadixScrollArea.Corner className={styles.ScrollAreaCorner} />
	</RadixScrollArea.Root>
);

export default ScrollArea;
