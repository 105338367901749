import * as CollapsibleRadix from '@radix-ui/react-collapsible';
import { CSSProperties, ReactNode, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';
import Typography from '../typography/typography.tsx';
import { Box, Flex } from '@radix-ui/themes';
import styles from './collapsible.module.css';
import clsx from 'clsx';
export interface CollapsibleProps {
	isOpen?: boolean;
	setOpen?: (e: boolean) => void;
	buttonContent?: string | ReactNode;
	type?: 'button' | 'icon';
	label?: string;
	className?: string;
	children?: ReactNode;
	style?: CSSProperties;
}

const Collapsible = ({
	children,
	type = 'button',
	className,
	label,
	buttonContent,
	isOpen,
	setOpen,
}: CollapsibleProps) => {
	const [openLocal, setOpenLocal] = useState(isOpen);
	const ButtonTrigger = () => (
		<>
			<div className={styles.CurtainLeft} />
			<div>
				<CollapsibleRadix.Trigger className={clsx(styles.ButtonTrigger, className)}>
					<div></div>
					{buttonContent}
					{isOpen || openLocal ? <ChevronUpIcon /> : <ChevronDownIcon />}{' '}
				</CollapsibleRadix.Trigger>
			</div>
			<div className={styles.CurtainRight} />
		</>
	);

	const IconTrigger = () => (
		<CollapsibleRadix.Trigger style={{ width: '100%' }} className={className}>
			<button className={clsx(styles.IconTrigger)}>
				{buttonContent}
				<Box className={styles.Icon}>
					{isOpen || openLocal ? <ChevronUpIcon /> : <ChevronDownIcon />}
				</Box>
			</button>
		</CollapsibleRadix.Trigger>
	);

	return (
		<div>
			{label && <Typography level="h4">{label}</Typography>}
			<CollapsibleRadix.Root
				open={isOpen || openLocal}
				onOpenChange={setOpen || setOpenLocal}
			>
				<Flex direction={'row'} align={'center'} justify={'between'}>
					{type === 'button' ? <ButtonTrigger /> : <IconTrigger />}
				</Flex>
				<CollapsibleRadix.Content className={clsx(type === 'button' && styles.Content)}>
					{children}
				</CollapsibleRadix.Content>
			</CollapsibleRadix.Root>
		</div>
	);
};

export default Collapsible;
