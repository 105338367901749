import { Flex, Tabs } from '@radix-ui/themes';
import Screen from '../../components/screen';
import Card from '../../components/card';
import {
	LockIcon,
	PreferencesIcon,
	SettingsIcon,
	UsersIcon,
} from '../../components/icons';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './settings.module.css';
import * as Label from '@radix-ui/react-label';
import { useMediaQuery } from 'react-responsive';
import { TABS } from './settings';
import { useState } from 'react';

const SettingsLayout = () => {
	const { t } = useTranslation('', { keyPrefix: 'settings' });
	const isTablet = useMediaQuery({ query: '(max-width: 580px)' });
	const [tab, setTab] = useState(TABS.USER_MANAGEMENT);

	// TODO: wrap tab buttons
	const TabsList = () => {
		return (
			<Tabs.List className={styles.tabList}>
				<Flex direction={'column'} align={'center'}>
					<Tabs.Trigger id={t('tabs.userManagement.title')} value={TABS.USER_MANAGEMENT}>
						{!isTablet && t('tabs.userManagement.title')} <UsersIcon />
					</Tabs.Trigger>
					{isTablet && (
						<Label.Root
							className={styles.tabLabel}
							htmlFor={t('tabs.userManagement.titleMobile')}
						>
							{t('tabs.userManagement.titleMobile')}
						</Label.Root>
					)}
				</Flex>
				<Flex direction={'column'} align={'center'}>
					<Tabs.Trigger id={t('tabs.changePassword.title')} value={TABS.PASSWORD}>
						{!isTablet && t('tabs.changePassword.title')} <LockIcon />
					</Tabs.Trigger>
					{isTablet && (
						<Label.Root
							className={styles.tabLabel}
							htmlFor={t('tabs.changePassword.title')}
						>
							{t('tabs.changePassword.title')}
						</Label.Root>
					)}
				</Flex>
				{/* TODO: comment until implemented */}
				{/* <Flex direction={'column'} align={'center'}>
					<Tabs.Trigger id={t('tabs.preferences.title')} value={TABS.PREFERENCES}>
						{!isTablet && t('tabs.preferences.title')} <PreferencesIcon />
					</Tabs.Trigger>
					{isTablet && (
						<Label.Root className={styles.tabLabel} htmlFor={t('tabs.preferences.title')}>
							{t('tabs.preferences.title')}
						</Label.Root>
					)}
				</Flex> */}
			</Tabs.List>
		);
	};

	return (
		<Screen>
			<Card title={t('title')} titleIcon={<SettingsIcon />}>
				<Tabs.Root value={tab} onValueChange={setTab}>
					<TabsList />
					<Outlet />
				</Tabs.Root>
			</Card>
		</Screen>
	);
};

export default SettingsLayout;
