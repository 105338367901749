import React, { ReactNode } from 'react';
import * as Select from '@radix-ui/react-select';
import { ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';
import styles from './small-select.module.css';
import clsx from 'clsx';

interface SmallSelectorProps {
	options: { value: string; label: string }[];
	triggerIcon: ReactNode;
	value: string | undefined;
	onChange: (e: any) => void;
	placeholder?: string;
	className?: string;
	radius?: 'none' | 'full';
	type?: 'primary' | 'secondary' | 'black';
	disabled?: boolean;
}

const SmallSelect = (props: SmallSelectorProps) => {
	const getTriggerStyle = () => {
		switch (props.type) {
			case 'primary':
				return styles.primary;
			case 'secondary':
				return styles.secondary;
			case 'black':
				return styles.black;
			default:
				return null;
		}
	};

	return (
		<Select.Root onValueChange={props.onChange} value={props.value}>
			<Select.Trigger
				disabled={props.disabled}
				className={clsx(
					styles.SelectTrigger,
					props.radius === 'full' && styles.triggerRadius,
					getTriggerStyle(),
					props.className,
				)}
			>
				<Select.Value placeholder={props.placeholder || props.value} />
				<Select.Icon>{props.triggerIcon}</Select.Icon>
			</Select.Trigger>
			<Select.Content
				align="center"
				position="popper"
				className={clsx(
					styles.SelectContent,
					props.radius === 'full' && styles.contentRadius,
				)}
			>
				<>
					<Select.ScrollUpButton className={styles.SelectScrollButton}>
						<ChevronUpIcon />
					</Select.ScrollUpButton>
					<Select.Group>
						{props.options.map((x) => (
							<SelectItem key={x.value} value={x.value} radius={props.radius}>
								{x.label}
							</SelectItem>
						))}
					</Select.Group>
					<Select.ScrollDownButton className={styles.SelectScrollButton}>
						<ChevronDownIcon />
					</Select.ScrollDownButton>
				</>
			</Select.Content>
		</Select.Root>
	);
};

const SelectItem = React.forwardRef(({ children, ...props }, forwardedRef) => {
	return (
		<Select.Item
			className={clsx(
				styles.SelectItem,
				props.radius === 'full' && styles.radiusSelectItem,
			)}
			{...props}
			ref={forwardedRef}
		>
			<Select.ItemText>{children}</Select.ItemText>
			<Select.ItemIndicator className={styles.SelectItemIndicator} />
		</Select.Item>
	);
});

SelectItem.displayName = 'SelectItem';

export default SmallSelect;
