import clsx from 'clsx';
import styles from './link.module.css';
import React from 'react';
import { Link as ReactLink } from 'react-router-dom';

interface LinkProps extends React.LinkHTMLAttributes<HTMLLinkElement> {
	to: string;
	children: React.ReactNode;
	underline?: boolean;
	className?: string;
	state?: any;
	target?: '_blank' | '_self';
}

const Link = (props: LinkProps) => {
	const { to, children, underline, className, state, target } = props;
	return (
		<ReactLink
			to={to}
			className={clsx(styles.default, className, underline && styles.underline)}
			state={state}
			target={target}
		>
			{children}
		</ReactLink>
	);
};

export default Link;
