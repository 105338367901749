import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Box, Flex } from '@radix-ui/themes';
import styles from './userEditScreen.module.css';
import Typography from '../../components/typography/typography.tsx';
import NewSelect from '../../components/select/new-select.tsx';
import * as Separator from '@radix-ui/react-separator';
import Button from '../../components/button';
import { SimpleInput } from '../../components/input';
import Checkbox from '../../components/checkbox';
import { useNavigate } from 'react-router-dom';
import * as RadioGroup from '@radix-ui/react-radio-group';

const USER_STATUSES = [
	{ label: 'Enabled', value: 'enabled' },
	{ label: 'Disabled', value: 'disabled' },
];

const RIGHTS = [
	{ label: 'Sign', value: 'sign' },
	{ label: 'Create', value: 'create' },
];

const UserEditScreen = () => {
	const { t } = useTranslation('', { keyPrefix: 'settings.tabs.userManagement.user' });
	const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
	const navigate = useNavigate();

	const RegionalOptions = () => (
		<Flex direction={'column'} className={styles.container}>
			<Typography level={'h3'}>{t('title')}</Typography>
			<SimpleInput label={t('name')} />
			<SimpleInput label={t('userId')} />
			<SimpleInput label={t('email')} />
			<SimpleInput label={t('phone')} />
			<NewSelect options={USER_STATUSES} label={t('status')} />
			<Checkbox label={t('rightsCreate')} />
			<Checkbox label={t('rightsAdminister')} />
			<RadioGroup.Root
				className={styles.RadioGroupRoot}
				defaultValue="default"
				aria-label="View density"
			>
				{RIGHTS.map((item) => (
					<Flex
						align="center"
						justify="between"
						className={styles.RadioGroupItemContainer}
						key={item.value}
					>
						<label className={styles.Label} htmlFor="r1">
							{item.label}
						</label>
						<RadioGroup.Item className={styles.RadioGroupItem} value={item.value} id="r1">
							<RadioGroup.Indicator className={styles.RadioGroupIndicator} />
						</RadioGroup.Item>
					</Flex>
				))}
			</RadioGroup.Root>
		</Flex>
	);

	return (
		<Box>
			<RegionalOptions />
			<Separator.Root
				decorative
				orientation={'horizontal'}
				className={styles.SeparatorRoot}
			/>
			<Flex direction={isMobile ? 'column' : 'row'} className={styles.buttonsContainer}>
				<Button variant={'primary'} className={styles.button}>
					{t('confirm')}
				</Button>
				<Button
					variant={'secondary'}
					className={styles.button}
					onClick={() => navigate(-1)}
				>
					{t('cancel')}
				</Button>
			</Flex>
		</Box>
	);
};

export default UserEditScreen;
