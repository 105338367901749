import { Flex } from '@radix-ui/themes';
import Loader from '../../components/loader';
import { useAuth } from '../../hooks';
import { Navigate } from 'react-router-dom';

function OryLogin() {
	const { session } = useAuth();

	if (!session) {
		return (
			<Flex justify={'center'} align={'center'} height={'100vh'}>
				<Loader />
			</Flex>
		);
	}

	return <Navigate to="/dashboard" />;
}

export default OryLogin;
