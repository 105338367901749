import { Box, Table } from '@radix-ui/themes';
import OperationsIcon from '../../components/icons/operationsIcon';
import styles from './dashboard.module.css';
import { useTranslation } from 'react-i18next';
import Card from '../../components/card';
import { useMediaQuery } from 'react-responsive';
import Typography from '../../components/typography/typography';
import Alert from '../../components/alert';
import { useTransactions } from '../../api/hooks/useTransactions.ts';
import { useBankProfile } from '../../contexts/BankProfileContext.tsx';

const LatestOperations = () => {
	const { selectedProfile } = useBankProfile();
	const { data, isLoading, isError } = useTransactions(
		selectedProfile?.id,
		1,
		10,
		undefined,
		undefined,
		undefined,
		undefined,
		['executed'],
	);

	const { t } = useTranslation();
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 480px)' });

	const OperationsListMobile = () => {
		return (
			<Box className={styles.tableContainer}>
				{/*Product decision - show only 10*/}
				{data?.items.slice(0, 10).map((x) => (
					<table key={x.id}>
						<tr>
							<td className={styles.tableFirstColumn}>
								<Typography fontWeight="500" level="text">
									{x.payment_details}
								</Typography>
							</td>
							<td>
								<Typography fontWeight="400" level="text" color={'#656565'}>
									{x.currency}
								</Typography>
							</td>
						</tr>
						<tr>
							<td className={styles.tableFirstColumn}>
								<Typography fontWeight="500" level="text" color={'#656565'}>
									{x.execution_date}
								</Typography>
							</td>
							<td>
								<Typography level="h4">{x.amount.toFixed(2)}</Typography>
							</td>
						</tr>
					</table>
				))}
			</Box>
		);
	};

	const OperationsListDesktop = () => {
		return (
			<Box>
				<Table.Root>
					<Table.Header>
						<Table.Row>
							{/*<Table.ColumnHeaderCell className={styles.operationsTableHeaderCell}>*/}
							{/*	{t('dashboard.listOfOperationsCard.tableHeaders.operationNo')}*/}
							{/*</Table.ColumnHeaderCell>*/}
							<Table.ColumnHeaderCell className={styles.operationsTableHeaderCell}>
								{t('dashboard.listOfOperationsCard.tableHeaders.date')}
							</Table.ColumnHeaderCell>
							<Table.ColumnHeaderCell className={styles.operationsTableHeaderCell}>
								{t('dashboard.listOfOperationsCard.tableHeaders.docNo')}
							</Table.ColumnHeaderCell>
							<Table.ColumnHeaderCell className={styles.operationsTableHeaderCell}>
								{t('dashboard.listOfOperationsCard.tableHeaders.operation')}
							</Table.ColumnHeaderCell>
							<Table.ColumnHeaderCell className={styles.operationsTableHeaderCell}>
								{t('dashboard.listOfOperationsCard.tableHeaders.accountNo')}
							</Table.ColumnHeaderCell>
							<Table.ColumnHeaderCell className={styles.operationsTableHeaderCell}>
								{t('dashboard.listOfOperationsCard.tableHeaders.currency')}
							</Table.ColumnHeaderCell>
							<Table.ColumnHeaderCell className={styles.operationsTableHeaderCell}>
								{t('dashboard.listOfOperationsCard.tableHeaders.amount')}
							</Table.ColumnHeaderCell>
							<Table.ColumnHeaderCell className={styles.operationsTableHeaderCell}>
								{t('dashboard.listOfOperationsCard.tableHeaders.correspondent')}
							</Table.ColumnHeaderCell>
						</Table.Row>
					</Table.Header>

					<Table.Body style={{ fontWeight: 'bold' }}>
						{/*First 10, product decision*/}
						{data?.items.slice(0, 10).map((x) => (
							<Table.Row key={x.id}>
								{/*<Table.Cell>{x.document_number}</Table.Cell>*/}
								<Table.Cell>{x.execution_date}</Table.Cell>
								<Table.Cell>{x.document_number}</Table.Cell>
								<Table.Cell>{x.paymentType}</Table.Cell>
								<Table.Cell>{x.account}</Table.Cell>
								<Table.Cell>{x.currency}</Table.Cell>
								<Table.Cell>{x.amount.toFixed(2)}</Table.Cell>
								<Table.Cell>{x.beneficiary.name}</Table.Cell>
							</Table.Row>
						))}
					</Table.Body>
				</Table.Root>
			</Box>
		);
	};

	const LatestOperationsContainer = () => {
		if (isError) {
			return <Alert type="error">{t('common.failedFetching')}</Alert>;
		} else {
			return isTabletOrMobile ? <OperationsListMobile /> : <OperationsListDesktop />;
		}
	};

	return (
		<Card
			title={t('dashboard.listOfOperationsCard.title')}
			titleIcon={<OperationsIcon />}
			link="/operations"
			linkTitle={t('dashboard.listOfOperationsCard.link')}
			isLoading={isLoading}
		>
			<LatestOperationsContainer />
		</Card>
	);
};

export default LatestOperations;
