import { useTranslation } from 'react-i18next';
import { SimpleInput } from '../../../components/input/index.ts';
import styles from './additionalData.module.css';
import NewSelect from '../../../components/select/new-select.tsx';
import {
	Control,
	Controller,
	FieldErrors,
	UseFormRegister,
	UseFormResetField,
	UseFormWatch,
} from 'react-hook-form';
import { SepaPayment } from '../../../api/hooks/types/createTransaction.ts';
import Typography from '../../../components/typography/typography.tsx';
import { useEffect, useState } from 'react';
import { useGeoDataContext } from '../../../contexts/GeoDataContext.tsx';

const BENEFICIARY_ID_TYPE = [
	{ value: 'PERSONAL_ID_NUMBER', label: 'Identification Card' },
	{ value: 'ID_NUMBER', label: 'Passport' },
	{ value: 'DRIVER_LICENSE_NUMBER', label: 'Driver license' },
	{ value: 'PHONE_NUMBER', label: 'Phone' },
];

interface Props {
	control: Control<SepaPayment>;
	register: UseFormRegister<SepaPayment>;
	errors: FieldErrors<SepaPayment>;
	watch: UseFormWatch<SepaPayment>;
	resetField: UseFormResetField<SepaPayment>;
}

const AdditionalDataForm = ({ control, register, errors, watch, resetField }: Props) => {
	const { t } = useTranslation();
	const [beneficiaryAddressRequired, setBeneficiaryAddressRequired] = useState(false);
	const [ultimateBeneficiaryAddressRequired, setUltimateBeneficiaryAddressRequired] =
		useState(false);

	const [beneficiaryIdTypeRequired, setBeneficiaryIdTypeRequired] = useState(false);
	const [beneficiaryIdValueRequired, setBeneficiaryIdValueRequired] = useState(false);

	const [ultimateBeneficiaryAccountRequired, setUltimateBeneficiaryAccountRequired] =
		useState(false);
	const [ultimateBeneficiaryNameRequired, setUltimateBeneficiaryNameRequired] =
		useState(false);

	const [ultimateBeneficiaryIdTypeRequired, setUltimateBeneficiaryIdTypeRequired] =
		useState(false);
	const [ultimateBeneficiaryIdValueRequired, setUltimateBeneficiaryIdValueRequired] =
		useState(false);

	const [payerIdTypeRequired, setPayerIdTypeRequired] = useState(false);
	const [payerIdValueRequired, setPayerIdValueRequired] = useState(false);

	const [ultimatePayerIdTypeRequired, setUltimatePayerIdTypeRequired] = useState(false);
	const [ultimatePayerIdValueRequired, setUltimatePayerIdValueRequired] = useState(false);

	const geoData = useGeoDataContext();

	const beneficiaryAddress = watch([
		'beneficiary.address.line',
		'beneficiary.address.building_number',
		'beneficiary.address.town',
		'beneficiary.address.postal_code',
		'beneficiary.address.country',
	]);

	const ultimateBeneficiaryAddress = watch([
		'ultimate_beneficiary.address.line',
		'ultimate_beneficiary.address.building_number',
		'ultimate_beneficiary.address.town',
		'ultimate_beneficiary.address.postal_code',
		'ultimate_beneficiary.address.country',
	]);

	useEffect(() => {
		if (beneficiaryAddress.some((x) => !!x)) {
			setBeneficiaryAddressRequired(true);
		} else {
			setBeneficiaryAddressRequired(false);
		}
	}, [beneficiaryAddress]);

	useEffect(() => {
		if (ultimateBeneficiaryAddress.some((x) => !!x)) {
			setUltimateBeneficiaryAddressRequired(true);
		} else {
			setUltimateBeneficiaryAddressRequired(false);
		}
	}, [ultimateBeneficiaryAddress]);

	useEffect(() => {
		if (!!watch('beneficiary.id.type') || !!watch('beneficiary.id.value')) {
			setBeneficiaryIdTypeRequired(true);
			setBeneficiaryIdValueRequired(true);
		} else {
			setBeneficiaryIdTypeRequired(false);
			setBeneficiaryIdValueRequired(false);
		}
	}, [watch('beneficiary.id.type'), watch('beneficiary.id.value')]);

	useEffect(() => {
		if (!!watch('ultimate_beneficiary.account') || !!watch('ultimate_beneficiary.name')) {
			setUltimateBeneficiaryAccountRequired(true);
			setUltimateBeneficiaryNameRequired(true);
		} else {
			setUltimateBeneficiaryAccountRequired(false);
			setUltimateBeneficiaryNameRequired(false);
		}
	}, [watch('ultimate_beneficiary.account'), watch('ultimate_beneficiary.name')]);

	useEffect(() => {
		if (
			!!watch('ultimate_beneficiary.id.type') ||
			!!watch('ultimate_beneficiary.id.value')
		) {
			setUltimateBeneficiaryIdTypeRequired(true);
			setUltimateBeneficiaryIdValueRequired(true);
		} else {
			setUltimateBeneficiaryIdTypeRequired(false);
			setUltimateBeneficiaryIdValueRequired(false);
		}
	}, [watch('ultimate_beneficiary.id.type'), watch('ultimate_beneficiary.id.value')]);

	useEffect(() => {
		if (!!watch('payer.type') || !!watch('payer.value')) {
			setPayerIdTypeRequired(true);
			setPayerIdValueRequired(true);
		} else {
			setPayerIdTypeRequired(false);
			setPayerIdValueRequired(false);
		}
	}, [watch('payer.type'), watch('payer.value')]);

	useEffect(() => {
		if (!!watch('primary_payer.id.type') || !!watch('primary_payer.id.value')) {
			setUltimatePayerIdTypeRequired(true);
			setUltimatePayerIdValueRequired(true);
		} else {
			setUltimatePayerIdTypeRequired(false);
			setUltimatePayerIdValueRequired(false);
		}
	}, [watch('primary_payer.id.type'), watch('primary_payer.id.value')]);

	useEffect(() => {
		if (!watch('beneficiary.address.country')) {
			resetField('beneficiary.address.town');
			resetField('beneficiary.address.postal_code');
			resetField('beneficiary.address.building_number');
			resetField('beneficiary.address.line');
		}
	}, [watch('beneficiary.address.country')]);

	useEffect(() => {
		if (!watch('ultimate_beneficiary.address.country')) {
			resetField('ultimate_beneficiary.address.town');
			resetField('ultimate_beneficiary.address.postal_code');
			resetField('ultimate_beneficiary.address.building_number');
			resetField('ultimate_beneficiary.address.line');
			setUltimateBeneficiaryAccountRequired(false);
			setUltimateBeneficiaryNameRequired(false);
		}
		if (watch('ultimate_beneficiary.address.country')) {
			setUltimateBeneficiaryAccountRequired(true);
			setUltimateBeneficiaryNameRequired(true);
		}
	}, [watch('ultimate_beneficiary.address.country')]);

	return (
		<div className={styles.container}>
			<Typography level={'h4'}>
				{t('orders.newPayment.additionalData.beneficiary')}
			</Typography>

			<SimpleInput
				{...register('end_to_end', {
					required: false,
				})}
				label={t('orders.newPayment.additionalData.paymentNumber')}
				isError={!!errors.end_to_end}
			/>

			<Controller
				name={'beneficiary.address.country'}
				control={control}
				rules={{ required: beneficiaryAddressRequired }}
				render={({ ...field }) => {
					const { ref, ...rest } = field; // removes ref
					return (
						<NewSelect
							{...rest}
							label={t('orders.newPayment.additionalData.address.country')}
							options={geoData?.data?.countries}
							className={styles.selector}
							allowClear
							required={beneficiaryAddressRequired}
							isError={!!errors.beneficiary?.address?.country}
						/>
					);
				}}
			/>

			{watch('beneficiary.address.country') && (
				<>
					<SimpleInput
						{...register('beneficiary.address.line', {
							required: beneficiaryAddressRequired,
						})}
						isMandatory={beneficiaryAddressRequired}
						label={t('orders.newPayment.additionalData.address.street')}
						isError={!!errors.beneficiary?.address?.line}
					/>

					<SimpleInput
						{...register('beneficiary.address.town', {
							required: beneficiaryAddressRequired,
						})}
						isMandatory={beneficiaryAddressRequired}
						label={t('orders.newPayment.additionalData.address.town')}
						isError={!!errors.beneficiary?.address?.town}
					/>

					<SimpleInput
						{...register('beneficiary.address.building_number', {
							required: beneficiaryAddressRequired,
						})}
						isMandatory={beneficiaryAddressRequired}
						label={t('orders.newPayment.additionalData.address.buildingNumber')}
						isError={!!errors.beneficiary?.address?.building_number}
					/>

					<SimpleInput
						{...register('beneficiary.address.postal_code', {
							required: beneficiaryAddressRequired,
						})}
						isMandatory={beneficiaryAddressRequired}
						label={t('orders.newPayment.additionalData.address.postalCode')}
						isError={!!errors.beneficiary?.address?.postal_code}
					/>
				</>
			)}

			<Controller
				name={'beneficiary.id.type'}
				control={control}
				rules={{ required: beneficiaryIdTypeRequired }}
				render={({ ...field }) => {
					const { ref, ...rest } = field; // removes ref
					return (
						<NewSelect
							{...rest}
							label={t('orders.newPayment.additionalData.beneficiaryInfo.idType')}
							options={BENEFICIARY_ID_TYPE}
							className={styles.selector}
							allowClear
							required={beneficiaryIdTypeRequired}
							isError={!!errors.beneficiary?.id?.type}
						/>
					);
				}}
			/>
			<SimpleInput
				{...register('beneficiary.id.value', { required: beneficiaryIdValueRequired })}
				label={t('orders.newPayment.additionalData.beneficiaryInfo.idValue')}
				isMandatory={beneficiaryIdValueRequired}
				isError={!!errors.beneficiary?.id?.value}
			/>

			<Typography level={'h4'}>
				{t('orders.newPayment.additionalData.ultimateBeneficiary.title')}
			</Typography>

			<SimpleInput
				{...register('ultimate_beneficiary.account', {
					required: ultimateBeneficiaryAccountRequired,
				})}
				label={t('orders.newPayment.additionalData.ultimateBeneficiary.account')}
				isMandatory={ultimateBeneficiaryAccountRequired}
				isError={!!errors.ultimate_beneficiary?.account}
			/>
			<SimpleInput
				{...register('ultimate_beneficiary.name', {
					required: ultimateBeneficiaryNameRequired,
				})}
				label={t('orders.newPayment.additionalData.beneficiaryName')}
				isMandatory={ultimateBeneficiaryNameRequired}
				isError={!!errors.ultimate_beneficiary?.name}
			/>

			<Controller
				name={'ultimate_beneficiary.address.country'}
				control={control}
				rules={{ required: ultimateBeneficiaryAddressRequired }}
				render={({ ...field }) => {
					const { ref, ...rest } = field; // removes ref
					return (
						<NewSelect
							{...rest}
							label={t(
								'orders.newPayment.additionalData.ultimateBeneficiary.address.country',
							)}
							options={geoData?.data?.countries}
							className={styles.selector}
							allowClear
							required={ultimateBeneficiaryAddressRequired}
							isError={!!errors.ultimate_beneficiary?.address?.country}
						/>
					);
				}}
			/>

			{watch('ultimate_beneficiary.address.country') && (
				<>
					<SimpleInput
						{...register('ultimate_beneficiary.address.line', {
							required: ultimateBeneficiaryAddressRequired,
						})}
						isMandatory={ultimateBeneficiaryAddressRequired}
						label={t(
							'orders.newPayment.additionalData.ultimateBeneficiary.address.street',
						)}
						isError={!!errors.ultimate_beneficiary?.address?.line}
					/>

					<SimpleInput
						{...register('ultimate_beneficiary.address.town', {
							required: ultimateBeneficiaryAddressRequired,
						})}
						isMandatory={ultimateBeneficiaryAddressRequired}
						label={t('orders.newPayment.additionalData.ultimateBeneficiary.address.town')}
						isError={!!errors.ultimate_beneficiary?.address?.town}
					/>

					<SimpleInput
						{...register('ultimate_beneficiary.address.building_number', {
							required: ultimateBeneficiaryAddressRequired,
						})}
						isMandatory={ultimateBeneficiaryAddressRequired}
						label={t(
							'orders.newPayment.additionalData.ultimateBeneficiary.address.buildingNumber',
						)}
						isError={!!errors.ultimate_beneficiary?.address?.building_number}
					/>

					<SimpleInput
						{...register('ultimate_beneficiary.address.postal_code', {
							required: ultimateBeneficiaryAddressRequired,
						})}
						isMandatory={ultimateBeneficiaryAddressRequired}
						label={t(
							'orders.newPayment.additionalData.ultimateBeneficiary.address.postalCode',
						)}
						isError={!!errors.ultimate_beneficiary?.address?.postal_code}
					/>
				</>
			)}

			<Controller
				name={'ultimate_beneficiary.id.type'}
				control={control}
				rules={{ required: ultimateBeneficiaryIdTypeRequired }}
				render={({ ...field }) => {
					const { ref, ...rest } = field; // removes ref
					return (
						<NewSelect
							{...rest}
							label={t('orders.newPayment.additionalData.ultimateBeneficiaryIdType')}
							options={BENEFICIARY_ID_TYPE}
							className={styles.selector}
							allowClear
							required={ultimateBeneficiaryIdTypeRequired}
							isError={!!errors.ultimate_beneficiary?.id?.type}
						/>
					);
				}}
			/>
			<SimpleInput
				{...register('ultimate_beneficiary.id.value', {
					required: ultimateBeneficiaryIdValueRequired,
				})}
				label={t('orders.newPayment.additionalData.ultimateBeneficiary.idValue')}
				isMandatory={ultimateBeneficiaryIdValueRequired}
				isError={!!errors.ultimate_beneficiary?.id?.value}
			/>
			<Controller
				name={'payer.type'}
				control={control}
				rules={{ required: payerIdTypeRequired }}
				render={({ ...field }) => {
					const { ref, ...rest } = field; // removes ref
					return (
						<NewSelect
							{...rest}
							label={t('orders.newPayment.additionalData.payer.idType')}
							options={BENEFICIARY_ID_TYPE}
							className={styles.selector}
							allowClear
							required={payerIdTypeRequired}
							isError={!!errors.payer?.type}
						/>
					);
				}}
			/>
			<SimpleInput
				{...register('payer.value', { required: payerIdValueRequired })}
				label={t('orders.newPayment.additionalData.payer.idValue')}
				isMandatory={payerIdValueRequired}
				isError={!!errors.payer?.value}
			/>
			<Controller
				name={'primary_payer.id.type'}
				control={control}
				rules={{ required: ultimatePayerIdTypeRequired }}
				render={({ ...field }) => {
					const { ref, ...rest } = field; // removes ref
					return (
						<NewSelect
							{...rest}
							label={t('orders.newPayment.additionalData.ultimatePayer.idType')}
							options={BENEFICIARY_ID_TYPE}
							className={styles.selector}
							allowClear
							required={ultimatePayerIdTypeRequired}
							isError={!!errors.primary_payer?.id?.type}
						/>
					);
				}}
			/>
			<SimpleInput
				{...register('primary_payer.id.value', {
					required: ultimatePayerIdValueRequired,
				})}
				label={t('orders.newPayment.additionalData.ultimatePayer.idValue')}
				isMandatory={ultimatePayerIdValueRequired}
				isError={!!errors.primary_payer?.id?.value}
			/>
		</div>
	);
};

export default AdditionalDataForm;
