import { useEffect, useRef, useState } from 'react';

const DEFAULT_EVENTS: (keyof DocumentEventMap)[] = [
	'keypress',
	'mousemove',
	'touchmove',
	'click',
	'scroll',
];

export default function useIdle(timeout: number, initialState: boolean) {
	const [idle, setIdle] = useState<boolean>(initialState);
	const timer = useRef<number>();

	useEffect(() => {
		const handleEvents = () => {
			setIdle(false);

			if (timer.current) {
				window.clearTimeout(timer.current);
			}
			timer.current = window.setTimeout(() => {
				setIdle(true);
			}, timeout);
		};

		timer.current = window.setTimeout(() => {
			setIdle(true);
		}, timeout);

		DEFAULT_EVENTS.forEach((event) => document.addEventListener(event, handleEvents));

		return () => {
			DEFAULT_EVENTS.forEach((event) =>
				document.removeEventListener(event, handleEvents),
			);
		};
	}, [timeout]);

	return idle;
}
