import {
	Flex,
	Button as RadixButton,
	IconButton as RadixIconButton,
} from '@radix-ui/themes';
import styles from './button.module.css';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import { ButtonHTMLAttributes, ElementRef, FC, forwardRef, ReactNode } from 'react';
import * as Label from '@radix-ui/react-label';

interface ButtonProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'color'> {
	children?: string;
	variant?: 'primary' | 'inverted' | 'secondary' | 'link';
	className?: string;
	icon?: ReactNode;
	doNotShrinkOnMobile?: boolean;
}

type ButtonElement = ElementRef<'button'>;

const Button: FC<ButtonProps> = forwardRef<ButtonElement, ButtonProps>(
	({ children, variant, className, doNotShrinkOnMobile, ...props }, forwardRef) => {
		const isTabletOrMobile = useMediaQuery({ query: '(max-width: 480px)' });

		const typeToUse = () => {
			switch (variant) {
				case 'primary':
					return 'solid';
				case 'inverted':
					return 'solid';
				case 'secondary':
					return 'outline';
				case 'link':
					return 'ghost';
				default:
					return 'solid';
			}
		};

		if (isTabletOrMobile && !doNotShrinkOnMobile && props.icon) {
			return (
				<Flex direction={'column'} align={'center'}>
					<RadixIconButton
						variant={typeToUse()}
						id={children?.toLocaleLowerCase()}
						className={clsx(
							styles.default,
							className,
							variant === 'primary' && styles.primary,
							variant === 'secondary' && styles.secondary,
							variant === 'link' && styles.link,
						)}
						size="4"
						radius="full"
						{...props}
					>
						{props.icon}
					</RadixIconButton>
					<Label.Root className={styles.label} htmlFor={children?.toLocaleLowerCase()}>
						{children}
					</Label.Root>
				</Flex>
			);
		}

		return (
			<RadixButton
				ref={forwardRef}
				variant={typeToUse()}
				className={clsx(
					styles.default,
					className,
					variant === 'primary' && styles.primary,
					variant === 'secondary' && styles.secondary,
					variant === 'link' && styles.link,
					variant === 'inverted' && styles.inverted,
				)}
				// className={`${styles.default} ${variant === 'primary' ? styles.primary : styles.secondary} ${className}`}
				size="4"
				radius="full"
				{...props}
			>
				{children}
				{props.icon}
			</RadixButton>
		);
	},
);

Button.displayName = 'Button';

export default Button;
