import { createContext, useContext, ReactNode } from 'react';
import { useGeoData, IGeoDataResponse } from '../api/hooks/useUtilities';

interface GeoDataContextType {
  data: IGeoDataResponse | null;
  isLoading: boolean;
  error: Error | null;
}

const GeoDataContext = createContext<GeoDataContextType | null>(null);

export function GeoDataProvider({ children }: { children: ReactNode }) {
  const { data, isLoading, error } = useGeoData();
  return (
    <GeoDataContext.Provider value={{ data, isLoading, error }}>
      {children}
    </GeoDataContext.Provider>
  );
};

export const useGeoDataContext = () => {
  const context = useContext(GeoDataContext);
  if (context === undefined) {
    throw new Error('useGeoDataContext must be used within a GeoDataProvider');
  }
  return context;
};
