export default function ChatIcon({ id, className }: { id?: string; className?: any }) {
	return (
		<svg
			id={id}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path
				d="M1.95654 4.4347V18.1304L6.65219 14.6086C6.99049 14.3539 7.40265 14.2165 7.82611 14.2173H15.6522C16.7327 14.2173 17.6087 13.3413 17.6087 12.2608V4.4347C17.6087 3.35414 16.7327 2.47818 15.6522 2.47818H3.91306C2.83251 2.47818 1.95654 3.35414 1.95654 4.4347ZM3.91306 14.2173V4.4347H15.6522V12.2608H7.17459C6.75107 12.2596 6.3388 12.3971 6.00067 12.6521L3.91306 14.2173Z"
				fill="#120F13"
			/>
			<path
				d="M21.5218 22.0434V9.326C21.5218 8.24545 20.6458 7.36948 19.5652 7.36948V18.1304L17.4776 16.5651C17.1395 16.3101 16.7272 16.1727 16.3037 16.1738H6.84785C6.84785 17.2544 7.72381 18.1304 8.80437 18.1304H15.6522C16.0757 18.1296 16.4878 18.267 16.8261 18.5217L21.5218 22.0434Z"
				fill="#120F13"
			/>
		</svg>
	);
}
