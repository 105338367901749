import { OperationStatus } from '../useLatestOperations';

export interface Transaction {
	id: string;
	skaleet_id: string | null;
	status: OperationStatus;
	paymentType: string;
	amount: number;
	currency: string;
	accountId: string;
	account: string;
	execution_date: string;
	payment_urgency: string;
	payment_details: string;
	document_number: string;
	beneficiary: Beneficiary;
	ultimate_beneficiary: UltimateBeneficiary;
	primary_payer: never;
}

export interface Beneficiary {
	name: string;
	accountNumber: string;
	address: Address;
}

export interface UltimateBeneficiary {
	name: string;
	accountNumber: string;
	address: Address;
}

export interface Address {
	country: string;
	addressLine: string;
	postalCode: string;
	townName: string;
	buildingNumber: string;
}

export enum OPERATION_STATUS {
	FAILED = 'failed',
	UNSIGNED = 'unsigned',
	CREATED = 'created',
}
