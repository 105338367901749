import { Callout } from '@radix-ui/themes';
import InfoAlertIcon from '../icons/infoAlertIcon';
import SuccessAlertIcon from '../icons/successAlertIcon';
import WarningAlertIcon from '../icons/warningAlertIcon';
import ErrorAlertIcon from '../icons/errorAlertIcon';
import styles from './alert.module.css';
import { ReactNode } from 'react';
import classNames from 'classnames';

export interface AlertProps {
	children?: ReactNode;
	type?: 'info' | 'success' | 'warning' | 'error';
	className?: string;
}

const Alert = (props: AlertProps) => {
	const { type, className, children } = props;
	const getTypeStyle = () => {
		switch (type) {
			case 'info': {
				return styles.info;
			}
			case 'success': {
				return styles.success;
			}
			case 'error': {
				return styles.error;
			}
			case 'warning': {
				return styles.warning;
			}
		}
	};

	return (
		<Callout.Root className={classNames(styles.base, getTypeStyle(), className)}>
			<Callout.Icon>
				{type === 'info' && <InfoAlertIcon />}{' '}
				{type === 'success' && <SuccessAlertIcon />}
				{type === 'warning' && <WarningAlertIcon />}
				{type === 'error' && <ErrorAlertIcon />}
			</Callout.Icon>
			<Callout.Text>{children}</Callout.Text>
		</Callout.Root>
	);
};

export default Alert;
