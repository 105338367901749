import { Flex } from '@radix-ui/themes';
import { forwardRef } from 'react';
import * as RadixCheckbox from '@radix-ui/react-checkbox';
import { CheckIcon } from '@radix-ui/react-icons';
import styles from './checkbox.module.css';

interface CheckboxProps extends RadixCheckbox.CheckboxProps {
	label: string;
	className?: string;
	onChange?: (e: boolean) => void;
	isError?: boolean;
	checked?: boolean;
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, forwardRef) => {
	return (
		<Flex align={'center'} ref={forwardRef} className={props.className}>
			<label className={styles.Label} htmlFor={props.label.toLowerCase()}>
				{props.label}
			</label>
			<RadixCheckbox.Root
				className={styles.CheckboxRoot}
				id={props.label.toLowerCase()}
				checked={props.checked}
				onCheckedChange={props.onChange}
				onClick={(e) => e.stopPropagation()}
			>
				<RadixCheckbox.Indicator className={styles.CheckboxIndicator}>
					<CheckIcon />
				</RadixCheckbox.Indicator>
			</RadixCheckbox.Root>
		</Flex>
	);
});

Checkbox.displayName = 'Checkbox';
export default Checkbox;
