import { useState } from 'react';
import styles from './mobileButtons.module.css';
import DashboardIcon from '../icons/dashboardIcon.tsx';
import { useNavigate } from 'react-router-dom';
import {
	AccountsIcon,
	CardsIcon,
	ChatIcon,
	InfoIcon,
	OperationsIcon,
	OrdersIcon,
	SettingsIcon,
} from '../icons';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Cross1Icon, HamburgerMenuIcon } from '@radix-ui/react-icons';
import { ROUTES } from './ControlPanel.tsx';
import clsx from 'clsx';

const MobileButtons = () => {
	const navigate = useNavigate();
	const [isOpen, setOpen] = useState(false);
	const getIcon = (route: string) => {
		switch (route) {
			case 'dashboard':
				return <DashboardIcon id={route} />;
			case 'accounts':
				return <AccountsIcon id={route} />;
			case 'orders':
				return <OrdersIcon id={route} />;
			case 'operations':
				return <OperationsIcon id={route} />;
			case 'messages':
				return <ChatIcon id={route} />;
			case 'settings':
				return <SettingsIcon id={route} />;
			case 'info':
				return <InfoIcon id={route} />;
			case 'cards':
				return <CardsIcon id={route} />;
		}
	};

	const isActivePath = (route: string, partialy = false) => {
		if (partialy) {
			return location.pathname.startsWith(`/${route}`);
		} else {
			return route === location.pathname.replace('/', '');
		}
	};

	return (
		<DropdownMenu.Root onOpenChange={() => setOpen(!isOpen)}>
			<DropdownMenu.Trigger>
				<div className={styles.iconButton}>
					{isOpen ? <Cross1Icon /> : <HamburgerMenuIcon />}
				</div>
			</DropdownMenu.Trigger>
			<DropdownMenu.Portal>
				<DropdownMenu.Content sideOffset={5}>
					<div className={styles.dropdownMenuContent}>
						{ROUTES.map((item) => (
							<DropdownMenu.CheckboxItem
								key={item.value}
								className={clsx(
									styles.dropdownMenuItem,
									item.value === 'dashboard' && styles.inverted,
								)}
								checked={isActivePath(item.value, true)}
								onCheckedChange={() => navigate(item.value)}
							>
								<div className={styles.itemContainer}>
									{getIcon(item.value)}
									<label className="Label" htmlFor={item.value}>
										{item.label}
									</label>
								</div>
							</DropdownMenu.CheckboxItem>
						))}
					</div>
				</DropdownMenu.Content>
			</DropdownMenu.Portal>
		</DropdownMenu.Root>
	);
};

export default MobileButtons;
