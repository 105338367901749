export default function ErrorAlertIcon({ id }: { id?: string }) {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			id={id}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.0001 1.49988C17.7981 1.49988 22.5001 6.20194 22.5001 11.9999C22.5001 17.7979 17.7981 22.5 12.0001 22.5C6.20206 22.5 1.5 17.7979 1.5 11.9999C1.5 6.20194 6.20206 1.49988 12.0001 1.49988ZM10.8752 12.9035V7.49923C10.8752 6.87968 11.3805 6.37432 12.0001 6.37432C12.6196 6.37432 13.125 6.88438 13.125 7.49923V12.9035C13.125 13.5183 12.6196 14.0284 12.0001 14.0284C11.3805 14.0284 10.8752 13.523 10.8752 12.9035ZM11.9952 15.2349C12.6992 15.2349 13.2703 15.8059 13.2703 16.5099C13.2703 17.2139 12.6992 17.7849 11.9952 17.7849C11.2912 17.7849 10.7202 17.2139 10.7202 16.5099C10.7202 15.8059 11.2912 15.2349 11.9952 15.2349Z"
				fill="#FF0000"
			/>
		</svg>
	);
}
