import { UseQueryResult } from '@tanstack/react-query';
import { Account } from '../../api/hooks/useAccounts';
import { Box, Flex, Table } from '@radix-ui/themes';
import { CopyIcon } from '@radix-ui/react-icons';
import { useTranslation } from 'react-i18next';
import IconButton from '../../components/iconButton';
import styles from './accountInformation.module.css';
import { useMediaQuery } from 'react-responsive';
import Typography from '../../components/typography/typography';
import * as Separator from '@radix-ui/react-separator';
import { PaginatedItems } from '../../api/hooks/common.ts';
import * as Tooltip from '@radix-ui/react-tooltip';
import { useState } from 'react';

interface AccountInformationProps {
	accounts: UseQueryResult<PaginatedItems<Account[]>, Error>;
}

const AccountInformation = (props: AccountInformationProps) => {
	const { accounts } = props;
	const { t } = useTranslation('', { keyPrefix: 'dashboard.accountInformationCard' });
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 480px)' });
	const [openTooltips, setOpenTooltips] = useState<string[]>([]);

	const copyAccountNo = async (accNo: string) => {
		await navigator.clipboard.writeText(accNo);
		handleButtonClick(accNo);
	};

	const handleButtonClick = (accNo: string) => {
		setOpenTooltips((prev) => {
			return [...prev, accNo];
		});

		setTimeout(() => {
			setOpenTooltips((prev) => prev.filter((x) => x !== accNo));
		}, 2000);
	};

	const AccountListDesktop = () => {
		const Account = (acc: Account) => {
			return (
				<Table.Row key={acc.id}>
					<Table.Cell>{acc.ownerName}</Table.Cell>
					<Table.Cell>
						<Flex direction="row" gapX={'4'}>
							{!!acc.iban && (
								<>
									{acc.iban}
									<Tooltip.Provider>
										<Tooltip.Root open={openTooltips.includes(acc.iban)}>
											<Tooltip.Trigger>
												<CopyIcon
													onClick={() => {
														copyAccountNo(acc.iban);
													}}
													className={styles.iconButton}
												/>
											</Tooltip.Trigger>
											<Tooltip.Content sideOffset={5}>{t('copied')}</Tooltip.Content>
										</Tooltip.Root>
									</Tooltip.Provider>
								</>
							)}
						</Flex>
					</Table.Cell>
					<Table.Cell>{acc.currency}</Table.Cell>
					<Table.Cell>{acc.balance.toFixed(2)}</Table.Cell>
					<Table.Cell>{acc.reservedAmount.toFixed(2)}</Table.Cell>
					<Table.Cell>{acc.availableBalance.toFixed(2)}</Table.Cell>
				</Table.Row>
			);
		};

		return (
			<Box>
				<Table.Root>
					<Table.Header>
						<Table.Row>
							<Table.ColumnHeaderCell className={styles.tableHeaderCell}>
								{t('accountName')}
							</Table.ColumnHeaderCell>
							<Table.ColumnHeaderCell className={styles.tableHeaderCell}>
								{t('accountNo')}
							</Table.ColumnHeaderCell>
							<Table.ColumnHeaderCell className={styles.tableHeaderCell}>
								{t('currency')}
							</Table.ColumnHeaderCell>
							<Table.ColumnHeaderCell className={styles.tableHeaderCell}>
								{t('balance')}
							</Table.ColumnHeaderCell>
							<Table.ColumnHeaderCell className={styles.tableHeaderCell}>
								{t('reserved')}
							</Table.ColumnHeaderCell>
							<Table.ColumnHeaderCell className={styles.tableHeaderCell}>
								{t('availableBalance')}
							</Table.ColumnHeaderCell>
						</Table.Row>
					</Table.Header>

					<Table.Body style={{ fontWeight: 'bold' }}>
						{accounts.data?.items.map((x) => Account(x))}
					</Table.Body>
				</Table.Root>
			</Box>
		);
	};

	const AccountListMobile = () => {
		const Account = (acc: Account, i: number, total: number) => {
			return (
				<Box key={acc.id}>
					<Box>
						<Typography level={'small-text'}>{t('accountName')}</Typography>
						<Typography level={'text'}>{acc.ownerName}</Typography>
					</Box>
					<Box>
						<Typography level={'small-text'}>{t('accountNo')}</Typography>
						<Typography level={'text'}>{acc.iban}</Typography>
					</Box>
					<Flex direction={'row'} gap={'4'}>
						<Box>
							<Typography level={'small-text'}>{t('currency')}</Typography>
							<Typography level={'text'}>{acc.currency}</Typography>
						</Box>
						<Box>
							<Typography level={'small-text'}>{t('balance')}</Typography>
							<Typography level={'text'}>{acc.balance.toFixed(2)}</Typography>
						</Box>
						<Box>
							<Typography level={'small-text'}>{t('reserved')}</Typography>
							<Typography level={'text'}>{acc.reservedAmount.toFixed(2)}</Typography>
						</Box>
						<Box>
							<Typography level={'small-text'}>{t('availableBalance')}</Typography>
							<Typography level={'text'}>{acc.availableBalance.toFixed(2)}</Typography>
						</Box>
					</Flex>
					{total - 1 !== i && (
						<Separator.Root
							decorative
							orientation={'horizontal'}
							className={styles.separator}
						/>
					)}
				</Box>
			);
		};

		return (
			<Box className={styles.accountListContainerMobile}>
				{accounts.data?.items.map((acc, i) =>
					Account(acc, i, accounts.data?.items.length),
				)}
			</Box>
		);
	};

	return isTabletOrMobile ? <AccountListMobile /> : <AccountListDesktop />;
};

export default AccountInformation;
