import { Box, TextField } from '@radix-ui/themes';
import styles from './inputDropdown.module.css';
import Selector from '../select/select.tsx';
import clsx from 'clsx';
import { Controller } from 'react-hook-form';

interface SimpleInputProps extends TextField.RootProps {
	options: { value: string; label: string }[];
	label?: string;
	isMandatory?: boolean;
	leftText?: string;
	fullWidth?: boolean;
	inputRegister: any;
	selectorRegister: any;
	control: any;
	isError?: boolean;
}

const InputDropdown = (props: SimpleInputProps) => {
	const filterNumbers = (event: any) => {
		event.target.value = event.target.value
			.replace(/[^0-9.]/g, '')
			.replace(/(\..*?)\..*/g, '$1')
			.replace(/^0[^.]/, '0');
	};

	return (
		<Box className={props.className}>
			{props.label && (
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<label htmlFor={props.label?.toLowerCase()} className={styles.label}>
						{props.label}
					</label>
					{props.isMandatory && <div className={styles.mandatory}>*</div>}
				</div>
			)}
			<TextField.Root
				style={props.style}
				className={clsx(
					styles.default,
					!props.leftText && styles.padding,
					props.isError && styles.error,
				)}
				radius="none"
				placeholder={props.placeholder}
				type={props.type}
				id={props.label?.toLowerCase()}
				pattern={props.pattern}
				inputMode={props.inputMode}
				onInput={props.inputMode === 'numeric' ? filterNumbers : undefined}
				{...props.inputRegister}
			>
				<TextField.Slot className={clsx(props.fullWidth && styles.bold)}>
					{/* {props.options.find((x) => x.value === props.value)?.label} */}
				</TextField.Slot>
				<TextField.Slot className={styles.epic}>
					<Controller
						control={props.control}
						name={props.selectorRegister.name}
						render={({ ...field }) => {
							const { ref, ...rest } = field;
							return (
								<Selector
									{...rest}
									fullWidth={props.fullWidth}
									options={props.options || []}
									// {...props.selectorRegister}
									// onChange={onChange}
								/>
							);
						}}
					/>
				</TextField.Slot>
			</TextField.Root>
		</Box>
	);
};

export default InputDropdown;
