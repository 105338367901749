import { Box, Flex } from '@radix-ui/themes';
import clsx from 'clsx';
import styles from './settings.module.css';
import Typography from '../../components/typography/typography.tsx';
import Button from '../../components/button';
import * as Separator from '@radix-ui/react-separator';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { useBankProfile } from '../../contexts/BankProfileContext';
import useAuth from '../../hooks/useAuth';
import { useAccounts } from '../../api/hooks/index';
import Loader from '../../components/loader/index.ts';

const UserManagement = () => {
	const { t } = useTranslation('', { keyPrefix: 'settings' });
	const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
	const { selectedProfile } = useBankProfile();
	// const navigate = useNavigate();
	const { session } = useAuth();
	const accounts = useAccounts(1, 100, undefined, undefined, undefined, [
		'OPENED',
		'BLOCKED',
		'DEBIT_BLOCKED',
		'CREDIT_BLOCKED',
	]);

	const Customer = () => (
		<Flex direction={'column'} className={clsx(styles.marginTop, styles.rowSpacing)}>
			<Typography level={'h3'}>{t('tabs.userManagement.customer.title')}</Typography>
			<Box>
				<Typography level={'h4'}>{t('tabs.userManagement.customer.name')}</Typography>
				<Typography level={'text'} className={styles.data}>
					{selectedProfile?.company?.name ||
						`${selectedProfile?.individual?.firstName} ${selectedProfile.individual?.lastName}`}
				</Typography>
			</Box>
			<Box>
				<Typography level={'h4'}>{t('tabs.userManagement.customer.code')}</Typography>
				<Typography level={'text'} className={styles.data}>
					{selectedProfile?.company?.registrationCode ||
						selectedProfile?.individual?.dateOfBirth}
				</Typography>
			</Box>
			<Box>
				<Typography level={'h4'}>{t('tabs.userManagement.customer.address')}</Typography>
				<Typography level={'text'} className={styles.data}>
					{selectedProfile?.company
						? `${selectedProfile?.company?.address?.line}, ${selectedProfile.company?.address?.country}`
						: `${selectedProfile?.individual?.address?.line}, ${selectedProfile.individual?.address?.country}`}
				</Typography>
			</Box>
		</Flex>
	);

	const User = () => (
		<Flex direction={'column'} className={clsx(styles.marginTop, styles.rowSpacing)}>
			<Typography level={'h3'}>{t('tabs.userManagement.user.title')}</Typography>

			<Box className={styles.gridContainer}>
				<Box>
					<Typography level={'h4'}>{t('tabs.userManagement.user.name')}</Typography>
					<Typography level={'text'} className={styles.data}>
						{selectedProfile?.company?.name ||
							`${selectedProfile?.individual?.firstName} ${selectedProfile.individual?.lastName}`}
					</Typography>
				</Box>
				<Box>
					<Typography level={'h4'}>{t('tabs.userManagement.user.userId')}</Typography>
					<Typography level={'text'} className={styles.data}>
						{session?.identity?.traits?.email}
					</Typography>
				</Box>
				<Box>
					<Typography level={'h4'}>{t('tabs.userManagement.user.email')}</Typography>
					<Typography level={'text'} className={styles.data}>
						{session?.identity?.traits?.email}
					</Typography>
				</Box>

				<Box>
					<Typography level={'h4'}>{t('tabs.userManagement.user.phone')}</Typography>
					<Typography level={'text'} className={styles.data}>
						{session?.identity?.traits?.phone}
					</Typography>
				</Box>
				<Box>
					<Typography level={'h4'}>{t('tabs.userManagement.user.language')}</Typography>
					<Typography level={'text'} className={styles.data}>
						{'EN'}
					</Typography>
				</Box>
				<Box>
					<Typography level={'h4'}>{t('tabs.userManagement.user.status')}</Typography>
					<Typography level={'text'} className={styles.data}>
						{session?.identity?.state?.toUpperCase()}
					</Typography>
				</Box>

				<Box>
					<Typography level={'h4'}>
						{t('tabs.userManagement.user.rightsCreate')}
					</Typography>
					<Typography level={'text'} className={styles.data}>
						{['create', 'create_sign', 'manage'].includes(selectedProfile?.role)
							? t('tabs.userManagement.user.yes')
							: t('tabs.userManagement.user.no')}
					</Typography>
				</Box>
				<Box>
					<Typography level={'h4'}>
						{t('tabs.userManagement.user.rightsAdminister')}
					</Typography>
					<Typography level={'text'} className={styles.data}>
						{['manage'].includes(selectedProfile?.role)
							? t('tabs.userManagement.user.yes')
							: t('tabs.userManagement.user.no')}
					</Typography>
				</Box>
				<Box>
					<Typography level={'h4'}>
						{t('tabs.userManagement.user.confirmationLevel')}
					</Typography>
					<Typography level={'text'} className={styles.data}>
						{!selectedProfile?.requiredSignaturesCount
							? 0
							: selectedProfile?.requiredSignaturesCount}
					</Typography>
				</Box>
			</Box>

			{/* <Button
				className={styles.userButton}
				variant={'secondary'}
				onClick={() => navigate('/settings/user')}
			>
				{t('tabs.userManagement.user.button')}
			</Button> */}
		</Flex>
	);

	const SecuritySettings = () => (
		<Box className={clsx(styles.marginTop, styles.rowSpacing)}>
			<Typography level={'h3'}>
				{t('tabs.userManagement.securitySettings.title')}
			</Typography>
			<Box className={clsx(styles.gridContainer, styles.marginTop)}>
				<Box>
					<Typography level={'h4'}>
						{t('tabs.userManagement.securitySettings.loginType')}
					</Typography>
					<Typography level={'text'} className={styles.data}>
						{'Lukas Bil'}
					</Typography>
				</Box>
				<Box>
					<Typography level={'h4'}>
						{t('tabs.userManagement.securitySettings.mean')}
					</Typography>
					<Typography level={'text'} className={styles.data}>
						{'Lukas Bil'}
					</Typography>
				</Box>
				<Box>
					<Typography level={'h4'}>
						{t('tabs.userManagement.securitySettings.resetPassword')}
					</Typography>
					<Typography level={'text'} className={styles.data}>
						{'Lukas Bil'}
					</Typography>
				</Box>
			</Box>

			<Button className={clsx(styles.userButton, styles.marginTop)} variant={'secondary'}>
				{t('tabs.userManagement.securitySettings.button')}
			</Button>
		</Box>
	);

	const Accounts = () => {
		const ACCOUNTS_SETTINGS = [
			{
				id: '123',
				accountNumber: 'LT823400023810000257',
				currency: 'EUR',
				rights: 'Debit & Credit',
				operationLimit: '50000.00',
				debitLimitDay: '50000.00',
				debitLimitMonth: '50000.00',
			},
			{
				id: '124',
				accountNumber: 'LT823400023810000257',
				currency: 'EUR',
				rights: 'Debit & Credit',
				operationLimit: '50000.00',
				debitLimitDay: '50000.00',
				debitLimitMonth: '50000.00',
			},
			{
				id: '125',
				accountNumber: 'LT823400023810000257',
				currency: 'EUR',
				rights: 'Debit & Credit',
				operationLimit: '50000.00',
				debitLimitDay: '50000.00',
				debitLimitMonth: '50000.00',
			},
		];
		return (
			<Flex direction={'column'} className={clsx(styles.marginTop, styles.rowSpacing)}>
				<Typography level={'h3'}>{t('tabs.userManagement.accounts.title')}</Typography>

				{accounts.isLoading && <Loader />}

				{accounts.isSuccess &&
					accounts.data?.items.map((acc, i) => (
						<Box key={acc.id}>
							<Box className={styles.gridContainer}>
								<Box>
									<Typography level={'h4'}>
										{t('tabs.userManagement.accounts.accountNo')}
									</Typography>
									<Typography level={'text'} className={styles.data}>
										{acc.iban}
									</Typography>
								</Box>
								<Box>
									<Typography level={'h4'}>
										{t('tabs.userManagement.accounts.currency')}
									</Typography>
									<Typography level={'text'} className={styles.data}>
										{acc.currency}
									</Typography>
								</Box>
								<Box>
									<Typography level={'h4'}>
										{t('tabs.userManagement.accounts.rights')}
									</Typography>
									<Typography level={'text'} className={styles.data}>
										{acc.status}
									</Typography>
								</Box>
								{!isMobile && (
									<>
										<Box>
											<Typography level={'h4'}>
												{t('tabs.userManagement.accounts.operationLimit')}
											</Typography>
											<Typography level={'text'} className={styles.data}>
												{acc.operationLimit}
											</Typography>
										</Box>
										<Box>
											<Typography level={'h4'}>
												{t('tabs.userManagement.accounts.debitLimitDay')}
											</Typography>
											<Typography level={'text'} className={styles.data}>
												{acc.debitLimitDay}
											</Typography>
										</Box>
										<Box>
											<Typography level={'h4'}>
												{t('tabs.userManagement.accounts.debitLimitMonth')}
											</Typography>
											<Typography level={'text'} className={styles.data}>
												{acc.debitLimitMonth}
											</Typography>
										</Box>
									</>
								)}
								{/* TODO: hide until implemented */}
								{/* <Button
									className={styles.userButton}
									variant={'secondary'}
									onClick={() => navigate(`account/${acc.id}`)}
								>
									{t('tabs.userManagement.user.button')}
								</Button> */}
							</Box>
							{i !== ACCOUNTS_SETTINGS.length - 1 && (
								<Separator.Root
									decorative
									orientation={'horizontal'}
									className={styles.SeparatorRoot}
								/>
							)}
						</Box>
					))}
			</Flex>
		);
	};

	if (!selectedProfile)
		return (
			<div style={{ marginTop: '44px' }}>
				<Loader />
			</div>
		);

	return (
		<>
			<Customer />
			<Separator.Root
				decorative
				orientation={'horizontal'}
				className={styles.SeparatorRoot}
			/>
			<User />
			<Separator.Root
				decorative
				orientation={'horizontal'}
				className={styles.SeparatorRoot}
			/>
			{/* TODO: hide until implemented */}
			{/* <SecuritySettings />
			<Separator.Root
				decorative
				orientation={'horizontal'}
				className={styles.SeparatorRoot}
			/> */}
			<Accounts />
		</>
	);
};

export default UserManagement;
