import { ReactNode } from 'react';
import styles from './screen.module.css';
import { Flex } from '@radix-ui/themes';

const Screen = ({ children }: { children: ReactNode }) => {
	return (
		<Flex direction={'column'} justify={'between'} gap={'5'} className={styles.container}>
			{children}
		</Flex>
	);
};

export default Screen;
