import { Flex } from '@radix-ui/themes';
import { ErrorBoundary } from '@sentry/react';
import Card from './components/card';
import Alert from './components/alert';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const CustomFallback = () => {
	const { t } = useTranslation();

	return (
		<Flex direction={'column'} justify={'between'} gap={'5'}>
			<Card>
				<Alert type="error">{t('common.failed')}</Alert>
			</Card>
		</Flex>
	);
};

const SentryErrorBoundary = ({ children }: { children: React.ReactNode }) => {
	const location = useLocation();
	return (
		<ErrorBoundary key={location.pathname} fallback={<CustomFallback />}>
			{children}
		</ErrorBoundary>
	);
};

export default SentryErrorBoundary;
