import { ReactNode, CSSProperties, createElement } from 'react';
import styles from './typography.module.css';

type TagVariants = 'h1' | 'h2' | 'h3' | 'h4' | 'text' | 'small-text' | 'xsmall-text';

type TypographyProps = {
	level?: TagVariants;
	children: ReactNode;
	className?: any;
};

const Typography = ({
	level = 'text',
	children,
	className,
	...props
}: TypographyProps & CSSProperties) => {
	const tagToUse = () => {
		switch (level) {
			case 'h1':
				return 'h1';
			case 'h2':
				return 'h2';
			case 'h3':
				return 'h3';
			case 'h4':
				return 'h4';
			case 'text':
				return 'p';
			case 'small-text':
			case 'xsmall-text':
				return 'span';
			default:
				return 'span';
		}
	};
	return createElement(
		tagToUse(),
		{ className: `${styles.default} ${className}`, style: { ...props } },
		children,
	);
};

export default Typography;
