export default function RefreshIcon({ id, className }: { id?: string; className?: any }) {
	return (
		<svg
			width="12"
			height="12"
			viewBox="0 0 12 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			id={id}
			className={className}
		>
			<path
				d="M0 12V6.75H5.25L2.83725 9.165C3.66972 10.0163 4.80935 10.4973 6 10.5C7.90459 10.4972 9.60114 9.29566 10.236 7.5H10.2495C10.3354 7.25596 10.4004 7.00506 10.4438 6.75H11.9528C11.5748 9.74993 9.02364 11.9999 6 12H5.9925C4.40157 12.0048 2.87487 11.3728 1.75275 10.245L0 12ZM1.5555 5.25H0.0465C0.424312 2.25121 2.97375 0.00160763 5.99625 -3.05102e-05H6C7.59122 -0.00512188 9.11829 0.62688 10.2405 1.755L12 -3.05102e-05V5.25H6.75L9.1665 2.835C8.33317 1.98273 7.19197 1.50161 6 1.5C4.09541 1.50281 2.39886 2.70434 1.764 4.5H1.7505C1.66392 4.74383 1.59892 4.9948 1.55625 5.25H1.5555Z"
				fill="#00A5AD"
			/>
		</svg>
	);
}
