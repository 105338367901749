import { Box, Flex, Tabs } from '@radix-ui/themes';
import { useTranslation } from 'react-i18next';
import SwapIcon from '../../components/icons/swapIcon.tsx';
import styles from './orders.module.css';
import { useState } from 'react';
import Screen from '../../components/screen/screen.tsx';
import Card from '../../components/card';
import { useMediaQuery } from 'react-responsive';
import * as Label from '@radix-ui/react-label';
import { useAccounts } from '../../api/hooks/useAccounts';
import { GlobeIcon, UploadIcon } from '../../components/icons';
import { useBankProfile } from '../../contexts/BankProfileContext.tsx';
import SepaPaymentForm from './sepa/mainData.tsx';
import SwiftPaymentForm from './swift/mainData.tsx';
import Alert from '../../components/alert/index.ts';
import BatchScreen from './batch/batchScreen.tsx';
import { useFeatureContext } from '../../contexts/FeatureContext.tsx';

enum PAYMENT_TYPE {
	SEPA = 'sepa',
	INTERNATIONAL = 'imnternational',
	TEMPLATES = 'templates',
	BATCH = 'batch',
}

const NewTransfer = () => {
	const { t } = useTranslation();
	const [paymentType, setPaymentType] = useState(PAYMENT_TYPE.SEPA);
	const { isLoading, isError } = useAccounts(1);
	const isTablet = useMediaQuery({ query: '(max-width: 580px)' });
	const { data } = useFeatureContext();

	return (
		<Card title={t('orders.title')} titleIcon={<SwapIcon />} isLoading={isLoading}>
			{isError ? (
				<Alert type="error">{t('common.failedFetching')}</Alert>
			) : (
				<Box>
					<Tabs.Root value={paymentType} onValueChange={setPaymentType}>
						<Tabs.List className={styles.tabList}>
							{[
								{
									id: t('orders.newPayment.tab'),
									value: PAYMENT_TYPE.SEPA,
									icon: <SwapIcon />,
								},
								{
									id: t('orders.international.tab'),
									value: PAYMENT_TYPE.INTERNATIONAL,
									icon: <GlobeIcon />,
									disabled: data?.status !== 'OPENED',
								},
								// {
								// 	id: t('orders.batch.tab'),
								// 	value: PAYMENT_TYPE.BATCH,
								// 	icon: <UploadIcon />,
								// },
								// { id: t('orders.templates.tab'), value: PAYMENT_TYPE.TEMPLATES, icon: <TemplateIcon /> }, // TODO: uncomment when implemented
							].map((tab) => (
								<Flex key={tab.id} direction={'column'} align={'center'}>
									<Tabs.Trigger disabled={tab.disabled} id={tab.id} value={tab.value}>
										{!isTablet && t(tab.id)} {tab.icon}
									</Tabs.Trigger>
									{isTablet && (
										<Label.Root className={styles.tabLabel} htmlFor={tab.id}>
											{t(tab.id)}
										</Label.Root>
									)}
								</Flex>
							))}
						</Tabs.List>

						<Box>
							<Tabs.Content value={PAYMENT_TYPE.SEPA}>
								<SepaPaymentForm />
							</Tabs.Content>
							<Tabs.Content value={PAYMENT_TYPE.INTERNATIONAL}>
								<SwiftPaymentForm />
							</Tabs.Content>
							{/* <Tabs.Content value={PAYMENT_TYPE.BATCH}>
								<BatchScreen />
							</Tabs.Content> */}
							{/* TODO: uncomment when implemented */}
							{/* <Tabs.Content value={PAYMENT_TYPE.TEMPLATES}>
								<TemplatesScreen />
							</Tabs.Content> */}
						</Box>
					</Tabs.Root>
				</Box>
			)}
		</Card>
	);
};

const Orders = () => {
	return (
		<Screen>
			<NewTransfer />
		</Screen>
	);
};

export default Orders;
