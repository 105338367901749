import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export enum OperationStatus {
	SIGNED = 'signed',
	UNSGINED = 'unsigned',
	FAILED = 'failed',
	CREATED = 'created',
	PROCESSING = 'processing'
}

export interface Operation {
	id: string;
	operationNumber: string;
	date: string;
	documentNumber: string;
	operation: string;
	accountNumber: string;
	currency: string;
	amount: number;
	correspondent: string;
	beneficiary: string;
	status: OperationStatus;
}

function useLatestOperations() {
	const fetchLatestOperations = async (): Promise<Operation[]> => {
		const response = await axios.get(
			`${import.meta.env.VITE_API_HOST}/dashboard/latest-operations`,
		);
		return response.data;
	};

	return useQuery({
		queryKey: ['latest-operations'],
		queryFn: fetchLatestOperations,
	});
}

export { useLatestOperations };
