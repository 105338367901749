import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { Customer, useProfiles } from '../api/hooks/useProfiles';

export const KEY = 'gp-selected-profile';
const canSignRoles = ['create_sign', 'sign', 'manage'];

const ProfileContext = createContext<IProfileContext | null>(null);
interface IProfileContext {
	selectedProfile: Customer;
	canSign: boolean;
	setSelectedProfile: (account: Customer | null) => void;
}

export const removeSelectedProfile = () => {
	localStorage.removeItem(KEY);
};

export const ProfileProvider = ({ children }: { children: ReactNode }) => {
	const { data, isFetching, isError } = useProfiles();

	useEffect(() => {
		if (!selectedProfile && !isError && !isFetching) {
			if (data) {
				setSelectedProfile(data.customers[0]);
			}
		}
	}, [data?.customers, isError, isFetching]);

	const getDefaultValue = () => {
		const storedAccount = localStorage.getItem(KEY);

		if (storedAccount === 'undefined') {
			localStorage.removeItem(KEY);
		} else {
			return storedAccount ? JSON.parse(storedAccount) : data?.customers[0];
		}
	};

	const [selectedProfile, setSelectedProfile] = useState<Customer | null>(() =>
		getDefaultValue(),
	);

	const [canSign, setCanSign] = useState(false);

	useEffect(() => {
		if (selectedProfile) {
			localStorage.setItem(KEY, JSON.stringify(selectedProfile));
			setCanSign(canSignRoles.includes(selectedProfile.role));
		}
	}, [selectedProfile]);

	const value = {
		selectedProfile,
		setSelectedProfile,
		canSign,
	};

	return <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>;
};

export const useBankProfile = () => {
	const context = useContext(ProfileContext);
	if (!context) {
		throw new Error('useBankAccount must be used within a BankAccountProvider');
	}
	return context;
};
