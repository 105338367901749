import { Box, Flex } from '@radix-ui/themes';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Card from '../../components/card';
import { BalanceCardIcon, SwapIcon } from '../../components/icons';
import Button from '../../components/button';
import { useAccounts } from '../../api/hooks/useAccounts.ts';
import AccountInformation from './AccountInformation.tsx';
import styles from './accountBalance.module.css';
import Alert from '../../components/alert';

const AccountBalance = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const accounts = useAccounts(1, 5, undefined, undefined, undefined, [
		'OPENED',
		'BLOCKED',
		'DEBIT_BLOCKED',
		'CREDIT_BLOCKED',
	]);

	const AccountBalanceContainer = () => {
		if (accounts.isError) {
			return <Alert type="error">{t('common.failedFetching')}</Alert>;
		} else {
			return (
				<>
					<Flex>
						<Box>
							<Flex gap="5" style={{ flexWrap: 'wrap' }}>
								<Button
									variant="primary"
									onClick={() => navigate('/orders')}
									icon={<SwapIcon />}
								>
									{t('dashboard.accountBalanceCard.buttons.newTransfer')}
								</Button>
								{/* <Button variant="secondary" icon={<HandIcon />}>
									{t('dashboard.accountBalanceCard.buttons.getMoney')}
								</Button>
								<Button variant="secondary" icon={<RotateIcon />}>
									{t('dashboard.accountBalanceCard.buttons.exchange')}
								</Button>
								<Button variant="secondary" icon={<TimelineIcon />}>
									{t('dashboard.accountBalanceCard.buttons.rates')}
								</Button> */}
							</Flex>
						</Box>
					</Flex>
				</>
			);
		}
	};

	return (
		<Card
			title={t('dashboard.accountBalanceCard.title')}
			titleIcon={<BalanceCardIcon />}
			link="/accounts"
			linkTitle={t('dashboard.accountBalanceCard.link')}
			isLoading={accounts.isLoading}
		>
			<AccountBalanceContainer />
			<div className={styles.accountInfoContainer}>
				<AccountInformation accounts={accounts} />
			</div>
		</Card>
	);
};

export default AccountBalance;
