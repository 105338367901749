export default function WarningAlertIcon({ id }: { id?: string }) {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			id={id}
		>
			<path
				d="M21.533 20.9991H2.99997C2.64271 20.9991 2.31259 20.8085 2.13397 20.4991C1.95534 20.1897 1.95534 19.8085 2.13397 19.4991L11.4 3.49909C11.5787 3.1902 11.9086 3 12.2655 3C12.6224 3 12.9522 3.1902 13.131 3.49909L22.397 19.4991C22.5755 19.8083 22.5756 20.1893 22.3972 20.4986C22.2188 20.808 21.8891 20.9987 21.532 20.9991H21.533ZM11.267 15.9991V17.9991H12.2H12.265H12.33H13.265V15.9991H11.267ZM11.267 8.99909V13.9991H13.267V8.99909H11.267Z"
				fill="#FAB607"
			/>
		</svg>
	);
}
