import { Flex, TextField } from '@radix-ui/themes';
import styles from './simple-input.module.css';
import { ElementRef, forwardRef } from 'react';
import clsx from 'clsx';

interface SimpleInputProps extends TextField.RootProps {
	label?: string;
	isMandatory?: boolean;
	leftText?: string;
	className?: string;
	isError?: boolean;
	disabled?: boolean;
	value?: string;
}

type TextFieldRootElement = ElementRef<'input'>;

const SimpleInput = forwardRef<TextFieldRootElement, SimpleInputProps>(
	({ isError, label, leftText, className, isMandatory, ...props }, forwardRef) => (
		<Flex
			direction={'column'}
			align={'start'}
			className={clsx(styles.container, className)}
		>
			{label && (
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<label htmlFor={label?.toLowerCase()} className={styles.label}>
						{label}
					</label>
					{isMandatory && <div className={styles.mandatory}>*</div>}
				</div>
			)}
			<TextField.Root
				className={clsx(styles.default, isError && styles.error)}
				radius="none"
				id={label?.toLowerCase()}
				name={label?.toLowerCase()}
				ref={forwardRef}
				{...props}
			>
				{leftText && (
					<TextField.Slot className={styles.leftIcon}>{leftText}</TextField.Slot>
				)}
			</TextField.Root>
		</Flex>
	),
);

SimpleInput.displayName = 'TextField.Root';
export default SimpleInput;
