import { IconButton as RadixIconButton } from '@radix-ui/themes';
import * as Label from '@radix-ui/react-label';
import styles from './iconButton.module.css';
import { CSSProperties } from 'react';
import clsx from 'clsx';

interface IconButtonProps {
	children: React.ReactNode;
	onClick: () => void;
	isActive?: boolean;
	label?: string;
	labelId?: string;
	invert?: boolean;
	className?: string;
}

const IconButton = ({
	children,
	onClick,
	isActive,
	label,
	labelId,
	invert,
	className,
}: IconButtonProps & CSSProperties) => {
	return (
		<RadixIconButton
			variant="ghost"
			onClick={onClick}
			className={clsx(
				styles.base,
				invert && styles.invert,
				isActive && (invert ? styles.activeInvert : styles.active),
				className,
			)}
		>
			{children}
			{label && (
				<Label.Root className={styles.label} htmlFor={labelId}>
					{label}
				</Label.Root>
			)}
		</RadixIconButton>
	);
};

export default IconButton;
