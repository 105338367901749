import styles from './switch.module.css';
import * as RadixToggleGroup from '@radix-ui/react-toggle-group';
import { Box } from '@radix-ui/themes';

export interface SwitchProps {
	options: { value: string; label: string }[];
	className?: string;
	label?: string;
	defaultValue?: any;
	onChange?: any;
}

const Switch: React.FC<SwitchProps> = (props) => {
	return (
		<Box className={props.className}>
			{props.label && (
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<label htmlFor={props.label?.toLowerCase()} className={styles.label}>
						{props.label}
					</label>
				</div>
			)}
			<RadixToggleGroup.Root
				defaultValue={props.value || props.defaultValue}
				onValueChange={props.onChange}
				className={styles.ToggleGroup}
				type="single"
			>
				<RadixToggleGroup.Item
					className={styles.ToggleGroupItem}
					value={props.options[0].value}
					aria-label="Left aligned"
					disabled={props.options[0].value === props.value}
				>
					{props.options[0].label}
				</RadixToggleGroup.Item>
				<RadixToggleGroup.Item
					className={styles.ToggleGroupItem}
					value={props.options[1].value}
					aria-label="Center aligned"
					disabled={props.options[1].value === props.value}
				>
					{props.options[1].label}
				</RadixToggleGroup.Item>
			</RadixToggleGroup.Root>
		</Box>
	);
};

export default Switch;
