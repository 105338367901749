import AccountBalance from './AccountBalance';
import LatestOperations from './LatestOperations';
import Screen from '../../components/screen';

const Dashboard = () => {
	return (
		<Screen>
			<AccountBalance />
			<LatestOperations />
		</Screen>
	);
};

export default Dashboard;
