import { Box, Flex, Text } from '@radix-ui/themes';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import IconButton from '../iconButton/iconButton';
import DashboardIcon from '../icons/dashboardIcon';
import AccountsIcon from '../icons/accountIcon';
import OrdersIcon from '../icons/ordersIcon';
import OperationsIcon from '../icons/operationsIcon';
import ChatIcon from '../icons/chatIcon';
import SettingsIcon from '../icons/settingsIcon';
import InfoIcon from '../icons/infoIcon';
import { useMediaQuery } from 'react-responsive';
import styles from './control-panel.module.css';
import MobileButtons from './mobileButtons.tsx';
import { useProfiles } from '../../api/hooks/useProfiles.ts';
import Loader from '../loader';
import Alert from '../alert';
import * as Dialog from '@radix-ui/react-dialog';
import { useEffect, useState } from 'react';
import Button from '../button';
import * as RadioGroup from '../radio-button/radio-button.tsx';
import Typography from '../typography/typography.tsx';
import { RefreshIcon } from '../icons';
import { useBankProfile } from '../../contexts/BankProfileContext.tsx';

export const ROUTES = [
	{ value: 'dashboard', label: 'Dashboard' },
	{ value: 'accounts', label: 'Accounts' },
	// { value: 'cards', label: 'Cards' },
	{ value: 'orders', label: 'Orders' },
	{ value: 'operations', label: 'Operations' },
	// { value: 'messages', label: 'Messages' },
	{ value: 'settings', label: 'Settings' },
	{ value: 'info', label: 'Info' },
];

const ControlPanel = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const location = useLocation();
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 720px)' });
	const { data, isFetching, isError } = useProfiles();
	const [modalOpen, setModalOpen] = useState(false);
	const { setSelectedProfile, selectedProfile } = useBankProfile();
	const [tempAccount, setTempAccount] = useState<string | null>(null);

	useEffect(() => {
		setTempAccount(null);
	}, [modalOpen]);

	const selectAccount = () => {
		const acc = data?.customers.find((c) => c.id === tempAccount);
		if (acc) {
			setSelectedProfile(acc);
		}
		setModalOpen(!modalOpen);
	};

	const isActive = (route: string, partialy = false) => {
		if (partialy) {
			return location.pathname.startsWith(route);
		} else {
			return route === location.pathname;
		}
	};

	const DesktopButtons = () => (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				width: '100%',
				justifyContent: 'space-between',
				justifyItems: 'space-between',
				margin: '21px 0 19px 34px',
				overflow: 'hidden',
			}}
		>
			<IconButton
				label={t('layout.controlPanel.dashboard')}
				labelId="dashboard"
				isActive={isActive('/dashboard')}
				onClick={() => navigate('dashboard')}
				invert
			>
				<DashboardIcon id="dashboard" />
			</IconButton>
			<IconButton
				label={t('layout.controlPanel.accounts')}
				labelId="accounts"
				isActive={isActive('/accounts') || isActive('/accounts-statement', true)}
				onClick={() => navigate('accounts')}
			>
				<AccountsIcon id="accounts" />
			</IconButton>
			{/*<IconButton*/}
			{/*	label="Cards"*/}
			{/*	labelId="cards"*/}
			{/*	isActive={isActive('/cards')}*/}
			{/*	onClick={() => navigate('cards')}*/}
			{/*>*/}
			{/*	<CardsIcon id="cards" />*/}
			{/*</IconButton>*/}
			<IconButton
				label={t('layout.controlPanel.orders')}
				labelId="orders"
				isActive={isActive('/orders', true)}
				onClick={() => navigate('orders')}
			>
				<OrdersIcon id={'orders'} />
			</IconButton>
			<IconButton
				label={t('layout.controlPanel.operations')}
				labelId="operations"
				isActive={isActive('/operations')}
				onClick={() => navigate('operations')}
			>
				<OperationsIcon id={'operations'} />
			</IconButton>
			{/* <IconButton
				label={t('layout.controlPanel.messages')}
				labelId="messages"
				isActive={isActive('/messages')}
				onClick={() => navigate('messages')}
			>
				<ChatIcon id={'chat'} />
			</IconButton> */}
			<IconButton
				label={t('layout.controlPanel.settings')}
				labelId="settings"
				isActive={isActive('/settings')}
				onClick={() => navigate('settings')}
			>
				<SettingsIcon id={'settings'} />
			</IconButton>
			<IconButton
				label={t('layout.controlPanel.info')}
				labelId="info"
				isActive={isActive('/info')}
				onClick={() => navigate('info')}
			>
				<InfoIcon id={'info'} />
			</IconButton>
		</div>
	);

	const AccountModal = () => {
		const Content = () => {
			return (
				<>
					<RadioGroup.Root
						className={styles.RadioGroupRoot}
						value={tempAccount || selectedProfile.id}
						defaultValue={selectedProfile?.id}
						onValueChange={(id) => setTempAccount(id)}
					>
						{data?.customers.map((item) => (
							<Flex
								align="center"
								justify="between"
								className={styles.RadioGroupItemContainer}
								key={item.id}
							>
								<RadioGroup.Item
									className={styles.RadioGroupItem}
									value={item.id}
									id="r1"
								>
									<RadioGroup.Indicator className={styles.RadioGroupIndicator} />
								</RadioGroup.Item>
								<Box className={styles.gridContainer}>
									<Box>
										<Typography level={'h4'}>
											{t('layout.controlPanel.switchDialog.name')}
										</Typography>
										<Typography level={'text'} className={styles.data}>
											{item.name}
										</Typography>
									</Box>
								</Box>
							</Flex>
						))}
					</RadioGroup.Root>
				</>
			);
		};

		return (
			<Dialog.Root open={modalOpen}>
				<Dialog.Trigger asChild>
					<Button
						variant={'link'}
						className={styles.switchButton}
						doNotShrinkOnMobile
						icon={<RefreshIcon />}
						onClick={() => setModalOpen(!modalOpen)}
					>
						{t('layout.controlPanel.switchDialog.trigger')}
					</Button>
				</Dialog.Trigger>

				<Dialog.Overlay className={styles.DialogOverlay} />
				<Dialog.Content
					className={styles.DialogContent}
					onCloseAutoFocus={(e) => e.preventDefault()}
					onPointerDownOutside={() => setModalOpen(!modalOpen)}
					onEscapeKeyDown={() => setModalOpen(!modalOpen)}
				>
					<Dialog.Title className={styles.DialogTitle}>
						{t('layout.controlPanel.switchDialog.title')}
					</Dialog.Title>
					<Content />
					<div className={styles.dialogButtonsContainer}>
						<Dialog.Close asChild>
							<Button
								className={styles.button}
								variant={'primary'}
								onClick={() => {
									selectAccount();
								}}
							>
								{t('layout.controlPanel.switchDialog.select')}
							</Button>
						</Dialog.Close>
						<Dialog.Close asChild>
							<Button
								className={styles.button}
								variant={'secondary'}
								onClick={() => setModalOpen(!modalOpen)}
							>
								{t('layout.controlPanel.switchDialog.close')}
							</Button>
						</Dialog.Close>
					</div>
				</Dialog.Content>
			</Dialog.Root>
		);
	};

	const ProfileContainer = () => {
		if (isFetching) {
			return <Loader />;
		}
		if (isError) {
			return <Alert type="error">{t('common.failedFetching')}</Alert>;
		} else {
			return (
				<>
					<AccountsIcon />
					<Flex
						direction={'column'}
						style={{
							marginLeft: '17px',
							textAlign: 'left',
							width: '120px',
						}}
					>
						<Text>{t('layout.controlPanel.welcome')},</Text>
						<Text weight="bold">{data?.name}</Text>
						<AccountModal />
					</Flex>
				</>
			);
		}
	};

	return (
		<Flex gapX={'7'} justify={'between'} align={'center'} className={styles.container}>
			<Flex align={'center'} className={styles.nameContainer}>
				<ProfileContainer />
			</Flex>
			{isTabletOrMobile ? <MobileButtons /> : <DesktopButtons />}
		</Flex>
	);
};

export default ControlPanel;
