export default function BalanceCardIcon({
	id,
	className,
}: {
	id?: string;
	className?: string;
}) {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path
				d="M12 13.5C12 12.685 12.396 11.968 13 11.512C12.5698 11.1812 12.0426 11.0012 11.5 11C10.8369 11 10.201 11.2634 9.73219 11.7322C9.26335 12.2011 8.99996 12.8369 8.99996 13.5C8.99996 14.163 9.26335 14.7989 9.73219 15.2677C10.201 15.7366 10.8369 16 11.5 16C12.0426 15.9987 12.5698 15.8188 13 15.488C12.6903 15.2571 12.4387 14.9572 12.2651 14.6121C12.0915 14.267 12.0007 13.8863 12 13.5Z"
				fill="#120F13"
			/>
			<path
				d="M15.4999 16C16.8806 16 17.9999 14.8807 17.9999 13.5C17.9999 12.1193 16.8806 11 15.4999 11C14.1192 11 12.9999 12.1193 12.9999 13.5C12.9999 14.8807 14.1192 16 15.4999 16Z"
				fill="#120F13"
			/>
			<path
				d="M20 4H4C2.897 4 2 4.897 2 6V18C2 19.103 2.897 20 4 20H20C21.103 20 22 19.103 22 18V6C22 4.897 21.103 4 20 4ZM4 18V6H20L20.002 18H4Z"
				fill="#120F13"
			/>
		</svg>
	);
}
