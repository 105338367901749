import { Box, TextField } from '@radix-ui/themes';
import styles from './calendar-input.module.css';
import { useEffect, useState } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { DayPicker, Matcher } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import './day-picker.css';
import CalendarIcon from '../icons/calendarIcon.tsx';
import { format, Locale } from 'date-fns';
import clsx from 'clsx';
import { Cross2Icon } from '@radix-ui/react-icons';
import style from '../select/new-select.module.css';
import { lt, enUS } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';

interface CalendarInputProps extends TextField.RootProps {
	value: string | undefined;
	onChange: (e) => void;
	label?: string;
	isMandatory?: boolean;
	leftText?: string;
	fullWidth?: boolean;
	isError?: boolean;
	disabledDays?: Matcher;
	allowClear?: boolean;
}

const CalendarInput = (props: CalendarInputProps) => {
	const [selected, setSelected] = useState<Date>();
	const [opened, setOpened] = useState(false);
	const { i18n } = useTranslation();
	const onChng = props.field?.onChange ? props.field?.onChange : props.onChange;

	const [debounce, setDebounce] = useState(false);
	useEffect(() => {
		let timeoutId: number;
		if (debounce) {
			timeoutId = setTimeout(() => {
				setDebounce(false);
			}, 200);
		}

		return () => clearTimeout(timeoutId);
	}, [debounce]);

	return (
		<Box className={clsx(styles.container, props.className)}>
			{props.label && (
				<div style={{ display: 'flex', flexDirection: 'row', width: 'fit-content' }}>
					<label htmlFor={props.label?.toLowerCase()} className={styles.label}>
						{props.label}
					</label>
					{props.isMandatory && <div className={styles.mandatory}>*</div>}
				</div>
			)}
			<TextField.Root
				value={props.value || ''}
				// value={date!}
				onInput={(e) => props.onChange(e.target.value)}
				// onInput={(e) => setDate(e.target.value)}
				contentEditable={true}
				style={props.style}
				className={clsx(
					styles.default,
					!props.leftText && styles.padding,
					props.isError && styles.error,
				)}
				radius="none"
				placeholder={props.placeholder}
				type={props.type}
				id={props.label?.toLowerCase()}
				name={props.label?.toLowerCase()}
			>
				<TextField.Slot></TextField.Slot>
				<TextField.Slot style={{ padding: 0, cursor: 'pointer' }}>
					<DropdownMenu.Root open={opened}>
						<DropdownMenu.Trigger
							onClick={() => !debounce && setOpened(true)}
							className={styles.SelectTrigger}
						>
							{props.allowClear && props.value ? (
								<div
									onPointerDown={(e) => {
										e.preventDefault();
										e.stopPropagation();
										onChng('');
										setDebounce(true);
									}}
								>
									<Cross2Icon className={style.icon} />
								</div>
							) : (
								<CalendarIcon />
							)}
						</DropdownMenu.Trigger>
						<DropdownMenu.Portal>
							<DropdownMenu.Content
								side={'bottom'}
								align={'end'}
								className={styles.content}
								onPointerDownOutside={() => setOpened(false)}
								onEscapeKeyDown={() => setOpened(false)}
							>
								<DayPicker
									onDayClick={(e) => {
										setOpened(false);
										props.onChange(format(e, 'yyyy-MM-dd'));
									}}
									mode="single"
									selected={selected}
									onSelect={setSelected}
									disabled={props.disabledDays}
									locale={i18n.language === 'lt' ? lt : enUS}
								/>
							</DropdownMenu.Content>
						</DropdownMenu.Portal>
					</DropdownMenu.Root>
				</TextField.Slot>
			</TextField.Root>
		</Box>
	);
};

export default CalendarInput;
