import { SimpleInput } from '../../../components/input/index.ts';
import styles from './additionalData.module.css';
import NewSelect from '../../../components/select/new-select.tsx';
import {
	Control,
	Controller,
	FieldErrors,
	UseFormRegister,
	UseFormWatch,
} from 'react-hook-form';
import { SwiftPayment } from '../../../api/hooks/types/createTransaction.ts';
import { useEffect, useState } from 'react';
import { useGeoDataContext } from '../../../contexts/GeoDataContext.tsx';
import { useTranslation } from 'react-i18next';

interface Props {
	control: Control<SwiftPayment>;
	register: UseFormRegister<SwiftPayment>;
	errors: FieldErrors<SwiftPayment>;
	watch: UseFormWatch<SwiftPayment>;
}

const AdditionalDataForm = ({ control, register, errors, watch }: Props) => {
	const { t } = useTranslation(undefined, {
		keyPrefix: 'orders.newPayment.additionalData.swift.additionalData',
	});
	const [correspondentBIC, setCorrespondentBIC] = useState(false);
	const [correspondentBankName, setCorrespondentBankName] = useState(false);
	const [correspondentBankAddress, setCorrespondentBankAddress] = useState(false);
	const geoData = useGeoDataContext();

	useEffect(() => {
		if (
			!!watch('correspondent.bic') ||
			!!watch('correspondent.name') ||
			!!watch('correspondent.address.line') ||
			!!watch('correspondent.address.country')
		) {
			setCorrespondentBIC(true);
			setCorrespondentBankName(true);
			setCorrespondentBankAddress(true);
		} else {
			setCorrespondentBIC(false);
			setCorrespondentBankName(false);
			setCorrespondentBankAddress(false);
		}
	}, [
		watch('correspondent.bic'),
		watch('correspondent.name'),
		watch('correspondent.address.line'),
		watch('correspondent.address.country'),
	]);

	return (
		<div className={styles.container}>
			<SimpleInput
				{...register('correspondent.bic', { required: correspondentBIC })}
				label={t('bic')}
				isMandatory={correspondentBIC}
				isError={!!errors.correspondent?.bic}
			/>
			<SimpleInput
				{...register('correspondent.name', { required: correspondentBankName })}
				label={t('name')}
				isMandatory={correspondentBankName}
				isError={!!errors.correspondent?.name}
			/>
			<SimpleInput
				{...register('correspondent.address.line', {
					required: correspondentBankAddress,
				})}
				label={t('address')}
				isMandatory={correspondentBankAddress}
				isError={!!errors.correspondent?.address?.line}
			/>

			<Controller
				name={'correspondent.address.country'}
				control={control}
				rules={{ required: correspondentBankAddress }}
				render={({ ...field }) => {
					const { ref, ...rest } = field; // removes ref
					return (
						<NewSelect
							{...rest}
							label={t('country')}
							options={geoData?.data?.countries}
							className={styles.input}
							allowClear
							required={correspondentBankAddress}
							isError={!!errors.correspondent?.address?.country}
						/>
					);
				}}
			/>
		</div>
	);
};

export default AdditionalDataForm;
