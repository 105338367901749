import styles from './settings.module.css';
import { useTranslation } from 'react-i18next';
import { Box, Tabs } from '@radix-ui/themes';
import Preferences from './preferences';
import UserManagement from './userManagement';
import Link from '../../components/link';

export enum TABS {
	USER_MANAGEMENT = 'user_management',
	PASSWORD = 'password',
	PREFERENCES = 'preferences',
}

const Settings = () => {
	const { t } = useTranslation('', { keyPrefix: 'settings' });

	const Password = () => {
		return (
			<Box className={styles.passwordContainer}>
				<Link to={`${import.meta.env.VITE_ORY_BASE_PATH}/ui/settings`} target="_blank">
					{t('tabs.changePassword.change')}
				</Link>
			</Box>
		);
	};

	const TabsContent = () => {
		return (
			<>
				<Tabs.Content value={TABS.USER_MANAGEMENT}>
					<UserManagement />
				</Tabs.Content>
				<Tabs.Content value={TABS.PASSWORD}>
					<Password />
				</Tabs.Content>
				<Tabs.Content value={TABS.PREFERENCES}>
					<Preferences />
				</Tabs.Content>
			</>
		);
	};

	return <TabsContent />;
};

export default Settings;
