import * as Select from '@radix-ui/react-select';
import { ChevronDownIcon, Cross2Icon } from '@radix-ui/react-icons';
import style from './new-select.module.css';
import { Box, Flex } from '@radix-ui/themes';
import clsx from 'clsx';

export interface SelectProps {
	options: { value: string; label: string }[];
	placeholder?: string;
	onChange?: (e: string) => void;
	label?: string | undefined;
	value?: number | string;
	className?: string;
	allowClear?: boolean;
	required?: boolean;
	isError?: boolean;
	disabled?: boolean;
}

const NewSelect = (props: SelectProps) => {
	const { value, onChange } = props;
	const val = props.field?.value ? props.field?.value : value;
	const onChng = props.field?.onChange ? props.field?.onChange : onChange;
	const options = props.options || [];

	return (
		<Box style={{ width: '100%' }} className={props.className}>
			{props.label && (
				<Flex style={{ width: '100%', maxWidth: 'fit-content' }}>
					<label htmlFor={props.label?.toLowerCase()} className={style.label}>
						{props.label}
					</label>
					{props.required && <div className={style.mandatory}>*</div>}
				</Flex>
			)}
			<Select.Root key={val} onValueChange={onChng} value={val}>
				<Select.Trigger
					className={clsx(
						style.SelectTrigger,
						props.isError && style.error,
						props.disabled && style.disabled,
					)}
					disabled={props.disabled}
				>
					<Select.Value className={style.Value} />

					<Select.Icon>
						{props.allowClear && val ? (
							<div
								onPointerDown={(e) => {
									e.preventDefault();
									e.stopPropagation();
									onChng(undefined);
								}}
							>
								<Cross2Icon className={style.icon} />
							</div>
						) : (
							<ChevronDownIcon />
						)}
					</Select.Icon>
				</Select.Trigger>
				<Select.Content position="popper" className={`${style.SelectContent}`}>
					<Select.Viewport className="SelectViewport">
						{options.map((option) => (
							<SelectItem
								key={option.value}
								className={style.SelectItem}
								value={option.value}
							>
								{option.label}
							</SelectItem>
						))}
					</Select.Viewport>
				</Select.Content>
			</Select.Root>
		</Box>
	);
};

const SelectItem = ({ children, className, ...props }) => {
	return (
		<Select.Item className={clsx('SelectItem', className)} {...props}>
			<Select.ItemText>{children}</Select.ItemText>
		</Select.Item>
	);
};

export default NewSelect;
