import React from 'react';
import { usePagination, DOTS } from './utils';
import styles from './pagination.module.css';
import clsx from 'clsx';

interface PaginationProps {
	totalCount: number | undefined;
	currentPage: number;
	pageSize: number;
	onPageChange: (page: number) => void;
	siblingCount?: number;
	className?: string;
}

const Pagination: React.FC<PaginationProps> = ({
	onPageChange,
	totalCount,
	siblingCount = 1,
	currentPage,
	pageSize,
	className,
}) => {
	const paginationRange = usePagination({
		currentPage,
		totalCount,
		siblingCount,
		pageSize,
	});

	if (!paginationRange) {
		return null;
	}

	if (currentPage === 0 || paginationRange.length < 2) {
		return null;
	}

	const onNext = () => {
		onPageChange(currentPage + 1);
	};

	const onPrevious = () => {
		onPageChange(currentPage - 1);
	};

	const lastPage = paginationRange[paginationRange.length - 1];

	return (
		<ul className={clsx(styles['pagination-container'], className)}>
			<li
				className={`${styles['pagination-item']} ${currentPage === 1 && styles.disabled}`}
				onClick={onPrevious}
			>
				<div className={`${styles.arrow} ${styles.left}`} />
			</li>
			{paginationRange.map((pageNumber, index) => {
				if (pageNumber === DOTS) {
					return (
						<li
							key={`${pageNumber}-${index}`}
							className={`${styles['pagination-item']} ${styles.dots}`}
						>
							&#8230;
						</li>
					);
				}

				return (
					<li
						key={pageNumber}
						className={`${styles['pagination-item']} ${pageNumber === currentPage && styles.selected}`}
						onClick={() => onPageChange(pageNumber)}
					>
						{pageNumber}
					</li>
				);
			})}
			<li
				className={`${styles['pagination-item']} ${currentPage === lastPage && styles.disabled}`}
				onClick={onNext}
			>
				<div className={`${styles.arrow} ${styles.right}`} />
			</li>
		</ul>
	);
};

export default Pagination;
