import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useAuth } from '../../hooks';
import { useBankProfile } from '../../contexts/BankProfileContext';

interface IValidateIBANResponse {
	isValid: boolean;
	humanFormat: string;
	machineFormat: string;
}

interface IBankInfoResponse {
	countryCode: string;
	checkDigits: string;
	bankIcc: string;
	nationalId: string;
	accountNumber: string;
	ribKey: string;
	totalLength: number;
	bic: string;
	institutionName: string;
	serviceContext: string;
}

export interface IGeoDataResponse {
	countries: {
		code: string;
		name: string;
	}[];
	currencies: {
		default: string;
		currencies: {
			iso: string;
			symbol: string;
			name: string;
			decimalCount: number;
		}[];
	};
}

export interface IServiceStatusResponse {
	serviceLevel: 'SWIFT' | 'SEPA';
	status: 'OPENED' | 'CLOSED' | null;
}

function useValidateIBAN(iban?: string) {
	const { session } = useAuth();

	const validateIBAN = async (iban?: string): Promise<IValidateIBANResponse> => {
		const response = await axios.get(
			`${import.meta.env.VITE_API_HOST}/api/v1/utilities/iban-validity/${iban}`,
			{
				headers: { Authorization: session?.tokenized },
			},
		);

		return response.data;
	};

	return useQuery({
		queryKey: ['iban-validity', iban],
		queryFn: () => validateIBAN(iban),
		enabled: false,
	});
}

function useBankInfo(iban?: string, isValid?: boolean) {
	const { session } = useAuth();

	const getBankInfo = async (iban?: string): Promise<IBankInfoResponse> => {
		const response = await axios.get(
			`${import.meta.env.VITE_API_HOST}/api/v1/utilities/bank-info/${iban}`,
			{
				headers: { Authorization: session?.tokenized },
			},
		);

		return response.data;
	};

	return useQuery({
		queryKey: ['bank-info', iban],
		queryFn: () => getBankInfo(iban),
		enabled: !!isValid,
	});
}

function useGeoData() {
	const { session } = useAuth();

	const getCountriesAndCurrencies = async (): Promise<IGeoDataResponse> => {
		const response = await axios.get(
			`${import.meta.env.VITE_API_HOST}/api/v1/utilities/geo-data`,
			{
				headers: { Authorization: session?.tokenized },
			},
		);

		return response.data;
	};

	return useQuery({
		queryKey: ['geo-data'],
		queryFn: () => getCountriesAndCurrencies(),
		select: (data) => {
			const countries = data?.countries?.map((country) => ({
				value: country.code,
				label: country.name,
			}));

			const currencies = data?.currencies?.currencies?.map((curr) => ({
				value: curr.iso,
				label: curr.name,
				symbol: curr.symbol,
				decimalCount: curr.decimalCount,
			}));

			return {
				currencies,
				countries,
			};
		},
	});
}

function userServiceStatus(service?: 'swift' | 'sepa') {
	const { session } = useAuth();
	const { selectedProfile } = useBankProfile();

	const getServiceStatus = async (): Promise<IServiceStatusResponse> => {
		const response = await axios.get(
			`${import.meta.env.VITE_API_HOST}/api/v1/profiles/${selectedProfile.id}/service-status/${service}`,
			{
				headers: { Authorization: session?.tokenized },
			},
		);

		return response.data;
	};

	return useQuery({
		queryKey: ['service-status', service],
		queryFn: () => getServiceStatus(),
		enabled: !!service,
	});
}

export { useValidateIBAN, useBankInfo, useGeoData, userServiceStatus };
