export default function DownloadIcon({
	id,
	className,
}: {
	id?: string;
	className?: string;
}) {
	return (
		<svg
			width="12"
			height="15"
			viewBox="0 0 12 15"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			id={id}
			className={className}
		>
			<path
				d="M5.98479 7.63636V12.1818M5.98479 12.1818L8.25752 10.6667M5.98479 12.1818L3.71206 10.6667M6.74237 0.818835C6.67002 0.818176 6.58879 0.818176 6.49594 0.818176H3.10615C2.25759 0.818176 1.83299 0.818176 1.50888 0.983318C1.22379 1.12858 0.992167 1.3602 0.846904 1.6453C0.681763 1.9694 0.681763 2.394 0.681763 3.24257V12.0304C0.681763 12.879 0.681763 13.3031 0.846904 13.6272C0.992167 13.9123 1.22379 14.1443 1.50888 14.2896C1.83267 14.4545 2.25676 14.4545 3.10367 14.4545H8.86592C9.71282 14.4545 10.1363 14.4545 10.4601 14.2896C10.7452 14.1443 10.9776 13.9123 11.1228 13.6272C11.2878 13.3034 11.2878 12.8799 11.2878 12.033V5.61036C11.2878 5.51739 11.2878 5.43606 11.2871 5.36363M6.74237 0.818835C6.95876 0.820804 7.09565 0.828665 7.22634 0.86004C7.38094 0.897156 7.52862 0.958528 7.66418 1.0416C7.81704 1.13527 7.94829 1.26652 8.21017 1.5284L10.578 3.89619C10.84 4.15824 10.9703 4.2889 11.064 4.44181C11.1471 4.57737 11.2085 4.72519 11.2457 4.87979C11.277 5.01042 11.2851 5.14735 11.2871 5.36363M6.74237 0.818835V2.93939C6.74237 3.78795 6.74237 4.21194 6.90751 4.53605C7.05277 4.82114 7.28439 5.05339 7.56949 5.19865C7.89328 5.36363 8.31736 5.36363 9.16427 5.36363H11.2871M11.2871 5.36363H11.288"
				stroke="#120F13"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
