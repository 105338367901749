import { Box } from '@radix-ui/themes';
import Typography from '../../components/typography/typography';
import styles from './info.module.css';
import InfoIcon from '../../components/icons/infoIcon.tsx';
import Screen from '../../components/screen/screen';
import Card from '../../components/card';
import { useTranslation } from 'react-i18next';
import useAuth from '../../hooks/useAuth.ts';
import { useMemo } from 'react';

const Info = () => {
	const { t } = useTranslation('', { keyPrefix: 'info' });
	const { session } = useAuth();

	const getDate = (dateStr: string) =>
		useMemo(() => {
			const date = new Date(dateStr);

			const iso = date.toISOString().replace('T', ' ');
			return iso.substring(0, iso.lastIndexOf('.')).concat(' UTC');
		}, [session?.authenticated_at]);

	return (
		<Screen>
			<Card title={t('title')} titleIcon={<InfoIcon />} className={styles.card}>
				<Typography level={'h4'}>{t('header')}</Typography>
				<Box className={styles.container}>
					<Box>
						<Typography level={'h4'}>{t('ip')}</Typography>
						<Typography level={'text'} className={styles.data}>
							{session?.devices[0].ip_address}
						</Typography>
					</Box>
					<Box>
						<Typography level={'h4'}>{t('browser')}</Typography>
						<Typography level={'text'} className={styles.data}>
							{session?.devices[0].user_agent}
						</Typography>
					</Box>
					<Box>
						<Typography level={'h4'}>{t('loggedIn')}</Typography>
						<Typography level={'text'} className={styles.data}>
							{getDate(session?.authenticated_at)}
						</Typography>
					</Box>
				</Box>
			</Card>
		</Screen>
	);
};

export default Info;
