import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useAuth } from '../../hooks';
import { PaginatedItems } from './common.ts';
import { Transaction } from './types/getTransactions.ts';
import { NewPaymentPayload } from './types/createTransaction.ts';
import { useBankProfile } from '../../contexts/BankProfileContext.tsx';
import { OPERATION_TYPE } from '../../pages/operations/operations.tsx';

export const STATEMENTS_PAGE_SIZE = 50;

function useCreateTransaction() {
	const { session } = useAuth();
	const { selectedProfile } = useBankProfile();

	const updatePayload = (data: NewPaymentPayload) => {
		const { ultimate_beneficiary, beneficiary } = data;
		if (
			ultimate_beneficiary &&
			!ultimate_beneficiary.account &&
			!ultimate_beneficiary.name &&
			!ultimate_beneficiary.address?.line &&
			!ultimate_beneficiary.address?.country &&
			!ultimate_beneficiary.id?.value &&
			!ultimate_beneficiary.id?.type
		) {
			delete data.ultimate_beneficiary;
		}

		if (
			ultimate_beneficiary?.id &&
			!ultimate_beneficiary.id.type &&
			!ultimate_beneficiary.id.value
		) {
			delete data.ultimate_beneficiary?.id;
		}

		if (
			ultimate_beneficiary?.address &&
			!ultimate_beneficiary.address.line &&
			!ultimate_beneficiary.address.country
		) {
			delete data.ultimate_beneficiary?.address;
		}

		if (beneficiary && !beneficiary.address?.line && !beneficiary.address?.country) {
			delete data.beneficiary.address;
		}

		if (data.payment_type === 'INTERNATIONAL') {
			const { correspondent } = data;
			if (
				correspondent &&
				!correspondent.bic &&
				!correspondent.name &&
				!correspondent.address.line
			) {
				delete data?.correspondent;
			}
		}

		if (data.payment_type === 'DOMESTIC') {
			const { primary_payer } = data;
			if (primary_payer && !primary_payer.id?.value && !primary_payer.id?.value) {
				delete data?.primary_payer;
			}
		}

		if (beneficiary && !beneficiary.id?.value && !beneficiary.id?.type) {
			delete data.beneficiary.id;
		}

		data.amount = data.amount * 100;
		data.document_number = data.document_number.toString();

		return data;
	};

	const createTransaction = async (variables: { data: NewPaymentPayload }) => {
		const { data } = variables;
		const updated = updatePayload(data);
		const response = await axios.post(
			`${import.meta.env.VITE_API_HOST}/api/v1/profiles/${selectedProfile.id}/transactions`,
			updated,
			{
				headers: { Authorization: session?.tokenized },
			},
		);
		return response.data;
	};

	return useMutation({
		mutationFn: createTransaction,
		onSuccess: async (data) => {
			return data;
		},
		onError: async (data) => {
			return data;
		},
	});
}

function useSignTransaction() {
	const { session } = useAuth();
	const { selectedProfile } = useBankProfile();

	const signTransaction = async (props: { transactionId: string; code: string }) => {
		const { transactionId } = props;

		const response = await axios.patch(
			`${import.meta.env.VITE_API_HOST}/api/v1/profiles/${selectedProfile.id}/transactions/${transactionId}/sign`,
			{
				code: props.code,
			},
			{
				headers: { Authorization: session?.tokenized },
			},
		);

		return response.data;
	};

	return useMutation({
		mutationFn: signTransaction,
		onSuccess: async (data) => {
			return data;
		},
		onError: async (data) => {
			return data;
		},
	});
}

function useInitiateSigning() {
	const { session } = useAuth();
	const { selectedProfile } = useBankProfile();

	const initiateSigning = async (props: { transactionId: string }) => {
		const { transactionId } = props;

		const response = await axios.patch(
			`${import.meta.env.VITE_API_HOST}/api/v1/profiles/${selectedProfile.id}/transactions/${transactionId}/initiate-signing`,
			null,
			{
				headers: { Authorization: session?.tokenized },
			},
		);

		return response.data;
	};

	return useMutation({
		mutationFn: initiateSigning,
		onSuccess: async (data) => {
			return data;
		},
		onError: async (data) => {
			return data;
		},
	});
}

function useTransactions(
	customerId: string,
	page: number,
	limit = STATEMENTS_PAGE_SIZE,
	dateFrom?: string,
	dateTo?: string,
	type?: OPERATION_TYPE,
	documentNumber?: string,
	status?: string[],
) {
	const { session } = useAuth();
	const offset = page === 1 ? 0 : (page - 1) * limit;

	const fetchTransactions = async (
		customerId?: string,
	): Promise<PaginatedItems<Transaction[]>> => {
		const response = await axios.get(
			`${import.meta.env.VITE_API_HOST}/api/v1/profiles/${customerId}/transactions`,
			{
				params: {
					offset,
					limit,
					...(dateFrom && { dateFrom }),
					...(dateTo && { dateTo }),
					...(type && { type }),
					...(status?.length > 0 && { status: status?.join(',') }),
					...(documentNumber && { documentNumber }),
				},
				headers: { Authorization: session?.tokenized },
			},
		);

		return response.data;
	};

	return useQuery({
		queryKey: [
			'transactions',
			customerId,
			page,
			limit,
			dateFrom,
			dateTo,
			type,
			documentNumber,
			status,
		],
		queryFn: () => fetchTransactions(customerId),
		enabled: !!customerId,
		select: (data) => {
			const items = data?.items?.map((transaction) => ({
				...transaction,
				amount: transaction.amount / 100,
			}));

			return {
				...data,
				items,
			};
		},
	});
}

function useDocumentNumber(accountId?: string) {
	const { session } = useAuth();
	const { selectedProfile } = useBankProfile();

	const fetchDocumentNumber = async (
		accountId?: string,
	): Promise<{ documentNumber: string }> => {
		const response = await axios.get(
			`${import.meta.env.VITE_API_HOST}/api/v1/profiles/${selectedProfile.id}/transactions/document-number/${accountId}`,
			{
				headers: { Authorization: session?.tokenized },
			},
		);

		return response.data;
	};

	return useQuery({
		queryKey: ['document-number', accountId],
		queryFn: () => fetchDocumentNumber(accountId),
		enabled: !!accountId,
	});
}

function useProofOfPayment(transactionId?: string) {
	const { session } = useAuth();
	const { selectedProfile } = useBankProfile();

	const fetchProofOfPayment = async () => {
		const response = await axios.get(
			`${import.meta.env.VITE_API_HOST}/api/v1/profiles/${selectedProfile.id}/transactions/${transactionId}/proof-of-payment`,
			{
				headers: { Authorization: session?.tokenized },
				responseType: 'blob',
			},
		);

		return response.data;
	};

	return useQuery({
		queryKey: ['proof-of-payment', transactionId],
		queryFn: () => fetchProofOfPayment(),
		enabled: false,
		refetchOnWindowFocus: false,
	});
}

export {
	useTransactions,
	useCreateTransaction,
	useSignTransaction,
	useDocumentNumber,
	useInitiateSigning,
	useProofOfPayment,
};
