import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useAuth } from '../../hooks';
import { Session } from '@ory/client';

export interface Profile {
	id: string;
	name: string;
	customers: Customer[];
}

export interface Customer {
	id: string;
	legalType: string;
	status: string;
	name: string;
	role: 'view' | 'create' | 'sign' | 'create_sign' | 'manage';
	requiredSignaturesCount: number | null;
	company: Company;
	individual: Individual;
}

export interface Company {
	name: string;
	registrationCode: string;
	address: {
		line: string;
		town: string;
		country: string;
		postal_code: string;
		building_number: string;
	};
}

export interface Individual {
	firstName: string;
	lastName: string;
	dateOfBirth: string;
	address: {
		line: string;
		town: string;
		country: string;
		postal_code: string;
		building_number: string;
	};
}

function useProfiles() {
	const { session } = useAuth();

	const fetchLatestOperations = async (
		session: Session | undefined,
	): Promise<Profile> => {
		const response = await axios.get(`${import.meta.env.VITE_API_HOST}/api/v1/profiles`, {
			headers: { Authorization: session?.tokenized },
		});

		return response.data;
	};

	return useQuery({
		queryKey: ['profiles'],
		queryFn: () => fetchLatestOperations(session),
	});
}

export { useProfiles };
