import { useTranslation } from 'react-i18next';
import Typography from '../../components/typography/typography';
import NewSelect from '../../components/select/new-select.tsx';
import { Box, Flex } from '@radix-ui/themes';
import styles from './preferences.module.css';
import * as Separator from '@radix-ui/react-separator';
import Button from '../../components/button';
import { useMediaQuery } from 'react-responsive';

const LANGUAGES = [
	{ label: 'Lietuvių', value: 'lt' },
	{ label: 'English', value: 'en' },
];

const DATE_FORMATS = [
	{ label: '2024-04-23', value: '-' },
	{ label: '2024.04.23', value: '.' },
];

const TIME_FORMATS = [
	{ label: '16:46:46', value: 'seconds' },
	{ label: '16:46', value: 'minutes' },
	{ label: '04:46:46 PM', value: '12h-seconds' },
	{ label: '04:46 PM', value: '12h-minutes' },
];

const AMOUNT_FORMATS = [
	{ label: '1234567,89', value: ',' },
	{ label: '1234567.89', value: '.' },
	{ label: '1234 567.89', value: ' .' },
	{ label: '1234 567,89', value: ' .' },
];

const Preferences = () => {
	const { t } = useTranslation('', { keyPrefix: 'settings.tabs.preferences' });
	const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

	const RegionalOptions = () => (
		<Flex direction={'column'} className={styles.container}>
			<Typography level={'h3'}>{t('regional.title')}</Typography>
			<NewSelect options={LANGUAGES} label={t('regional.language')} />
			<NewSelect options={DATE_FORMATS} label={t('regional.date')} />
			<NewSelect options={TIME_FORMATS} label={t('regional.time')} />
			<NewSelect options={AMOUNT_FORMATS} label={t('regional.amount')} />
		</Flex>
	);

	const InterfaceOptions = () => (
		<Flex direction={'column'} className={styles.container}>
			<Typography level={'h3'}>{t('interface.title')}</Typography>
			<NewSelect options={LANGUAGES} label={t('interface.load')} />
			<NewSelect options={DATE_FORMATS} label={t('interface.email')} />
			<NewSelect options={TIME_FORMATS} label={t('interface.list')} />
			<NewSelect options={AMOUNT_FORMATS} label={t('interface.wait')} />
		</Flex>
	);

	return (
		<Box>
			<RegionalOptions />
			<Separator.Root
				decorative
				orientation={'horizontal'}
				className={styles.SeparatorRoot}
			/>
			<InterfaceOptions />
			<Flex direction={isMobile ? 'column' : 'row'} className={styles.buttonsContainer}>
				<Button variant={'primary'} className={styles.button}>
					{t('saveButton')}
				</Button>
				<Button variant={'secondary'} className={styles.button}>
					{t('defaultButton')}
				</Button>
			</Flex>
		</Box>
	);
};

export default Preferences;
