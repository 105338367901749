import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Box, Flex } from '@radix-ui/themes';
import styles from './accountEditScreen.module.css';
import Typography from '../../components/typography/typography.tsx';
import { SimpleInput } from '../../components/input';
import Button from '../../components/button';
import NewSelect from '../../components/select/new-select.tsx';
import { useNavigate } from 'react-router-dom';

const ACCOUNT_RIGHTS = [{ label: 'Debit & Credit', value: 'debit-credit' }];

const AccountEditScreen = () => {
	const { t } = useTranslation('', {
		keyPrefix: 'settings.tabs.userManagement.accounts',
	});
	const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
	const isTablet = useMediaQuery({ query: '(max-width: 760px)' });
	const navigate = useNavigate();

	const RegionalOptions = () => (
		<Flex direction={'column'} className={styles.container}>
			<Typography level={'h3'}>{t('title')}</Typography>
			<Flex direction={isTablet ? 'column' : 'row'} className={styles.nameContainer}>
				<SimpleInput label={t('name')} />
				<Box>
					<Typography level={'h4'}>{t('accountNo')}</Typography>
					<Typography level={'small-text'} className={styles.data}>
						LT823400023810000257
					</Typography>
				</Box>
				<Box>
					<Typography level={'h4'}>{t('currency')}</Typography>
					<Typography level={'small-text'} className={styles.data}>
						EUR
					</Typography>
				</Box>
			</Flex>
			<NewSelect options={ACCOUNT_RIGHTS} label={t('rights')} />
			<SimpleInput label={t('operationLimit')} />
			<SimpleInput label={t('debitLimitDay')} />
			<SimpleInput label={t('debitLimitMonth')} />
		</Flex>
	);

	return (
		<Box>
			<RegionalOptions />
			<Flex direction={isMobile ? 'column' : 'row'} className={styles.buttonsContainer}>
				<Button variant={'primary'} className={styles.button}>
					{t('confirm')}
				</Button>
				<Button
					variant={'secondary'}
					className={styles.button}
					onClick={() => navigate(-1)}
				>
					{t('cancel')}
				</Button>
			</Flex>
		</Box>
	);
};

export default AccountEditScreen;
