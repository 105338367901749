export default function DashboardIcon({ id }: { id: string }) {
	return (
		<svg
			id={id}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M20 20H14V16H20V20ZM10 20H4V12H10V20ZM20 12H14V4L20 4V12ZM10 8H4V4L10 4V8Z"
				stroke="#120F13"
				strokeWidth="2"
			/>
		</svg>
	);
}
