import { ReactNode } from 'react';
import { Box, Flex } from '@radix-ui/themes';
import Typography from '../typography/typography';
import Link from '../link';
import styles from './card.module.css';
import Loader from '../loader';
import clsx from 'clsx';

interface CardProps {
	title?: string;
	titleIcon?: ReactNode;
	children?: ReactNode;
	linkTitle?: string;
	link?: string;
	isLoading?: boolean;
	className?: string;
}

export const LoaderContainer = () => (
	<Flex className={styles.container} justify={'center'}>
		<Loader />
	</Flex>
);

const Card = (props: CardProps) => {
	return (
		<Box className={clsx(styles.container, props.className)}>
			{props.title && (
				<Flex mb={'5'} align={'center'} justify={'between'}>
					<Flex align={'center'}>
						<Flex className={styles.icon}>{props.titleIcon}</Flex>
						<Typography marginLeft={'13px'} level="h4">
							{props.title}
						</Typography>
					</Flex>

					{props.link && <Link to={props.link}>{props.linkTitle}</Link>}
				</Flex>
			)}

			{props.isLoading ? <LoaderContainer /> : props.children}
		</Box>
	);
};

export default Card;
