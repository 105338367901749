import { Outlet } from 'react-router-dom';
import ControlPanel from '../ControlPanel/ControlPanel';
import Header from '../header/header';
import { useEffect, useState } from 'react';
import useIdle from '../../hooks/useIdle';
import IdleDialog from '../../blocks/idleModal/idleModal';
import { ErrorBoundary } from '@sentry/react';
import SentryErrorBoundary from '../../SentryErrorBoundary';

const Layout = () => {
	const idle = useIdle(import.meta.env.VITE_IDLE_TIMEOUT_MINUTES * 1000 * 60, false);
	const [idleExpired, setIdleExpired] = useState(false);

	useEffect(() => {
		if (idle) {
			setIdleExpired(true);
		}
	}, [idle]);

	return (
		<div
			style={{
				alignItems: 'center',
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
			}}
		>
			<Header />
			<main
				style={{
					width: '100%',
					maxWidth: '1216px',
					display: 'flex',
					flexDirection: 'column',
					paddingBottom: '84px',
					// borderColor: '#646cff',
					// borderWidth: '2px',
					// borderStyle: 'solid',
				}}
			>
				<IdleDialog open={idleExpired} onActiveClick={setIdleExpired} />
				{/* <Notification /> */}
				<ControlPanel />
				<SentryErrorBoundary>
					<Outlet />
				</SentryErrorBoundary>
			</main>
		</div>
	);
};

export default Layout;
