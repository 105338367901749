import { Box, Flex, Table } from '@radix-ui/themes';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AccountsIcon from '../../components/icons/accountIcon.tsx';
import styles from './accounts.module.css';
import { useEffect, useState } from 'react';
import { SimpleInput } from '../../components/input';
import { Account, ACCOUNTS_PAGE_SIZE, useAccounts } from '../../api/hooks/useAccounts.ts';
import Screen from '../../components/screen';
import Card, { LoaderContainer } from '../../components/card/card.tsx';
import Pagination from '../../components/pagination/index.ts';
import { Collapsible } from '../../components/collapsible/index.tsx';
import { useMediaQuery } from 'react-responsive';
import Typography from '../../components/typography/typography.tsx';
import Alert from '../../components/alert';
import Button from '../../components/button';
import clsx from 'clsx';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import NewSelect from '../../components/select/new-select.tsx';
import { useGeoDataContext } from '../../contexts/GeoDataContext.tsx';

const Accounts = () => {
	const { t } = useTranslation();
	const [filtersOpen, setFiltersOpen] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [iban, setIban] = useState<string | undefined>();
	const [name, setName] = useState<string | undefined>();
	const [currency, setCurrency] = useState<string | undefined>();
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 480px)' });
	const accounts = useAccounts(currentPage, ACCOUNTS_PAGE_SIZE, iban, name, currency, [
		'OPENED',
		'BLOCKED',
		'DEBIT_BLOCKED',
		'CREDIT_BLOCKED',
	]);
	const geoData = useGeoDataContext();

	interface Filters {
		iban: string;
		name: string;
		currency: string;
	}

	const { register, handleSubmit, control, watch } = useForm<Filters>({
		mode: 'onBlur',
		reValidateMode: 'onChange',
	});

	const onSubmit: SubmitHandler<Filters> = (data) => {
		setIban(data.iban);
		setName(data.name);
		setCurrency(data.currency);
	};

	useEffect(() => {
		if (currency && !watch('currency')) {
			// This is needed to be able to clean currency dropdown..
		}
	}, [watch('currency')]);

	const Filters = () => {
		return (
			<form onSubmit={handleSubmit(onSubmit)}>
				<Box className={styles.filtersContainer}>
					<SimpleInput {...register('iban')} label={t('accounts.filters.byIban')} />
					<SimpleInput {...register('name')} label={t('accounts.filters.byName')} />
					<Controller
						name={'currency'}
						control={control}
						render={({ ...field }) => {
							const { ref, ...rest } = field; // removes ref
							return (
								<NewSelect
									{...rest}
									label={t('accounts.filters.byCurrency')}
									options={geoData?.data?.currencies}
									allowClear
								/>
							);
						}}
					/>
					<Button
						variant={'primary'}
						type={'submit'}
						className={clsx(styles.filtersButton, isTabletOrMobile && styles.fullWidth)}
					>
						{t('accounts.filters.button')}
					</Button>
				</Box>
			</form>
		);
	};

	const AccountListMobile = () => {
		const Account = (acc: Account) => {
			return (
				<table key={acc.id}>
					<tr>
						<td className={styles.tableFirstColumn}>
							<Typography fontWeight="700" level="text">
								{acc.ownerName}
							</Typography>
						</td>
						<td>
							<Typography fontWeight="700" level="text">
								{t('accounts.item.headers.currency')}
							</Typography>
						</td>
					</tr>
					<tr>
						<td>
							<Link className={styles.link} to={`/accounts-statement/${acc.id}`}>
								{acc.iban}
							</Link>
						</td>
						<td>
							<Typography fontWeight="400" level="text">
								{acc.currency}
							</Typography>
						</td>
					</tr>
				</table>
			);
		};

		return (
			<Box className={styles.tableContainer}>
				{accounts.data?.items.map((x) => Account(x))}
			</Box>
		);
	};

	const AccountList = () => {
		const Account = (acc: Account) => {
			return (
				<Table.Row key={acc.id}>
					<Table.Cell>{acc.ownerName}</Table.Cell>
					<Table.Cell>
						<Link className={styles.link} to={`/accounts-statement/${acc.id}`}>
							{acc.iban}
						</Link>
					</Table.Cell>
					<Table.Cell>{acc.currency}</Table.Cell>
					<Table.Cell>{acc.balance.toFixed(2)}</Table.Cell>
					<Table.Cell>{acc.reservedAmount.toFixed(2)}</Table.Cell>
					<Table.Cell>{acc.availableBalance.toFixed(2)}</Table.Cell>
				</Table.Row>
			);
		};

		return (
			<Flex
				justify={'between'}
				direction={'column'}
				className={styles.accountListContainer}
			>
				<Box>
					<Table.Root>
						<Table.Header>
							<Table.Row>
								<Table.ColumnHeaderCell className={styles.accountsTableHeaderCell}>
									{t('dashboard.accountInformationCard.accountName')}
								</Table.ColumnHeaderCell>
								<Table.ColumnHeaderCell className={styles.accountsTableHeaderCell}>
									{t('dashboard.accountInformationCard.accountNo')}
								</Table.ColumnHeaderCell>
								<Table.ColumnHeaderCell className={styles.accountsTableHeaderCell}>
									{t('dashboard.accountInformationCard.currency')}
								</Table.ColumnHeaderCell>
								<Table.ColumnHeaderCell className={styles.accountsTableHeaderCell}>
									{t('dashboard.accountInformationCard.balance')}
								</Table.ColumnHeaderCell>
								<Table.ColumnHeaderCell className={styles.accountsTableHeaderCell}>
									{t('dashboard.accountInformationCard.reserved')}
								</Table.ColumnHeaderCell>
								<Table.ColumnHeaderCell className={styles.accountsTableHeaderCell}>
									{t('dashboard.accountInformationCard.availableBalance')}
								</Table.ColumnHeaderCell>
							</Table.Row>
						</Table.Header>

						<Table.Body style={{ fontWeight: 'bold' }}>
							{accounts.data?.items.map((x) => Account(x))}
						</Table.Body>
					</Table.Root>
				</Box>
			</Flex>
		);
	};

	const AccountContainer = () => {
		if (accounts.isError) {
			return <Alert type="error">{t('common.failedFetching')}</Alert>;
		} else {
			return (
				<>
					<Collapsible
						isOpen={filtersOpen}
						setOpen={setFiltersOpen}
						buttonContent={t('accounts.filters.additionalFilters')}
						className={styles.collapsible}
					>
						<Filters />
					</Collapsible>
					{accounts.isLoading ? (
						<LoaderContainer />
					) : (
						<>
							{isTabletOrMobile ? <AccountListMobile /> : <AccountList />}
							<Flex justify={'center'}>
								<Pagination
									totalCount={accounts.data?.pagination.count}
									currentPage={currentPage}
									pageSize={ACCOUNTS_PAGE_SIZE}
									onPageChange={(page: number) => setCurrentPage(page)}
									className={styles.pagination}
								/>
							</Flex>
						</>
					)}
				</>
			);
		}
	};

	return (
		<Screen>
			<Card
				title={t('accounts.title')}
				titleIcon={<AccountsIcon />}
				// isLoading={accounts.isLoading}
			>
				<AccountContainer />
			</Card>
		</Screen>
	);
};

export default Accounts;
