import { forwardRef } from 'react';
import * as Select from '@radix-ui/react-select';
import classnames from 'classnames';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import style from './select.module.css';

export interface SelectProps {
	options: { value: any; label: string }[];
	fullWidth?: boolean;
	value: any;
	onChange: any;
}

const Selector = forwardRef<any, SelectProps>(
	({ fullWidth, options, name, value, onChange, ...props }, ref) => {
		const val = props.field?.value ? props.field?.value : value
		const onChng = props.field?.onChange ? props.field?.onChange : onChange

		return (
			<Select.Root
				defaultValue={value}
				value={val}
				key={val}
				onValueChange={onChng}
				//				onValueChange={(value) => props.onChange({ target: { name, value } })}
				{...props}
			>
				<Select.Trigger className={style.SelectTrigger}>
					<Select.Value />
					<Select.Icon className="SelectIcon">
						<ChevronDownIcon />
					</Select.Icon>
				</Select.Trigger>
				<Select.Portal>
					<Select.Content
						align={fullWidth && 'end'}
						position="popper"
						className={`${style.SelectContent} ${fullWidth && style.fullWidth}`}
					>
						<Select.Viewport className="SelectViewport">
							{options.map((option) => (
								<Select.Item
									key={option.value}
									className={classnames('SelectItem', style.SelectItem)}
									value={option.value}
								>
									<Select.ItemText>{option.label}</Select.ItemText>
								</Select.Item>
							))}
						</Select.Viewport>
					</Select.Content>
				</Select.Portal>
			</Select.Root>
		);
	},
);

Selector.displayName = 'Selector';

//const SelectItem = ({ children, className, ...props }) => {
//	console.log('inside', props);
//	return (
//		<Select.Item className={classnames('SelectItem', className)} {...props}>
//			<Select.ItemText>{children}</Select.ItemText>
//		</Select.Item>
//	);
//};
//
//SelectItem.displayName = 'SelectItem';

export default Selector;
