import { createContext, useContext, ReactNode } from 'react';
import { IServiceStatusResponse, userServiceStatus } from '../api/hooks/useUtilities';

interface FeatureContextType {
	data: IServiceStatusResponse | undefined;
	isLoading: boolean;
	error: Error | null;
}

const FeatureContext = createContext<FeatureContextType | null>(null);

export function FeatureProvider({ children }: { children: ReactNode }) {
	const { data, isLoading, error } = userServiceStatus('swift');
	return (
		<FeatureContext.Provider value={{ data, isLoading, error }}>
			{children}
		</FeatureContext.Provider>
	);
}

export const useFeatureContext = () => {
	const context = useContext(FeatureContext);
	if (context === undefined) {
		throw new Error('useFeatureContext must be used within a FeatureProvider');
	}
	return context;
};
