import * as Dialog from '@radix-ui/react-dialog';
import styles from './idleModal.module.css';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import Button from '../../components/button';
import useAuth from '../../hooks/useAuth.ts';

interface Props {
	open: boolean;
	onActiveClick: (e: boolean) => void;
}

const IdleDialog = (props: Props) => {
	const { t } = useTranslation('', { keyPrefix: 'idleModal' });
	const [seconds, setSeconds] = useState(60);
	const { logout } = useAuth();

	useEffect(() => {
		setSeconds(60);
	}, [props.open]);

	useEffect(() => {
		if (props.open) {
			const timer = setInterval(() => {
				setSeconds((prevSeconds: number) => {
					if (prevSeconds == 0) {
						clearInterval(timer);
						logout();
					} else {
						return prevSeconds - 1;
					}
				});
			}, 1000);

			return () => clearInterval(timer);
		}
	}, [logout, props.open]);

	return (
		<Dialog.Root open={props.open}>
			<Dialog.Overlay className={styles.DialogOverlay} />
			<Dialog.Content className={styles.DialogContent}>
				<Dialog.Title className={styles.DialogTitle}>{t('title')}</Dialog.Title>
				<Dialog.Description className={styles.DialogDescription}>
					{t('description', { seconds: seconds?.toString() })}
				</Dialog.Description>
				<div
					style={{
						display: 'flex',
						marginTop: 25,
						justifyContent: 'flex-start',
						gap: '12px',
					}}
				>
					<Button onClick={() => props.onActiveClick(false)} variant="primary">
						{t('stay')}
					</Button>
					<Button variant="secondary" onClick={logout}>
						{t('out')}
					</Button>
				</div>
			</Dialog.Content>
		</Dialog.Root>
	);
};

export default IdleDialog;
